import { httpsCallable, Functions } from 'firebase/functions';
import { SHA256 } from 'crypto-js';

const getToken = <T>(params: T): string => {
  return SHA256(JSON.stringify(params)).toString();
};

export const functionWrapper = <T, U>(functions: Functions, functionName: string, params: T) => {
  const func = httpsCallable<T, U>(functions, functionName);
  const token = getToken<T>(params);
  return func({ ...params, token });
};