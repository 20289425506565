import React, { useState, useEffect, useImperativeHandle } from 'react';
import { throttle } from 'lodash';

interface ScrollRevealInnerProps {
  children: () => React.ReactNode
}

export type ScrollRevealHandleProps = {
  init: () => void
};

const ScrollRevealInner: React.ForwardRefRenderFunction<ScrollRevealHandleProps, ScrollRevealInnerProps> = (
  props,
  ref,
) => {

  const [viewportHeight, setViewportheight] = useState(window.innerHeight);
  const [revealEl, setRevealEl] = useState<NodeListOf<HTMLElement> | null>(null);

  const checkComplete = () => {
    if (!revealEl) {
      return true;
    }
    return revealEl.length <= document.querySelectorAll('[class*=reveal-].is-revealed').length;
  };

  const elementIsVisible = (el: Element, offset: number) => (
    el.getBoundingClientRect().top <= viewportHeight - offset
  );

  const revealElements = () => {
    if (checkComplete()) return;
    if (!revealEl) {
      return;
    }
    for (let i = 0; i < revealEl.length; i++) {
      const el = revealEl[i];
      const revealDelay = Number(el.getAttribute('data-reveal-delay'));
      const revealOffset = Number((el.getAttribute('data-reveal-offset') ? el.getAttribute('data-reveal-offset') : '200'));
      const revealContainer = el.getAttribute('data-reveal-container');
      if (revealContainer) {
        const closestEl = el.closest(revealContainer);
        if (closestEl !== null) {
          const listenedEl = (el.getAttribute('data-reveal-container') ? closestEl : el);
          if (listenedEl !== null && revealOffset !== null) {
            if (elementIsVisible(listenedEl, revealOffset) && !el.classList.contains('is-revealed')) {
              if (revealDelay && revealDelay !== 0) {
                setTimeout(function () {
                  el.classList.add('is-revealed');
                }, revealDelay);
              } else {
                el.classList.add('is-revealed');
              }
            }
          }
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    init() {
      const el:NodeListOf<HTMLElement> = document.querySelectorAll('[class*=reveal-]');
      if (el !== null) {
        setRevealEl(el);
      }
    }
  }));

  useEffect(() => {
    if (typeof revealEl !== 'undefined' && revealEl !== null && revealEl.length > 0) {
      if (!checkComplete()) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
      }
      revealElements();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealEl]);

  const handleListeners = () => {
    if (!checkComplete()) return;
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleResize);
  };

  const handleScroll = throttle(() => {
    handleListeners();
    revealElements();
  }, 30);

  const handleResize = throttle(() => {
    setViewportheight(window.innerHeight);
  }, 30);

  useEffect(() => {
    handleListeners();
    revealElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportHeight]);

  return (
    <>
      {props.children()}
    </>
  );
}

const ScrollReveal = React.forwardRef(ScrollRevealInner);

export default ScrollReveal;
