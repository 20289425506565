import React, { useContext, useState } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import BackArrow from "../../assets/images/BackArrow.png";
import Details from "./Details";
import MoreInfo from "./MoreInfo";
import ProgramPreview from "./ProgramPreview";
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import AuthContext from "../../contexts/AuthContext";
import { Path, ProgramStatus, ProgramType, SiteType, UserType } from "@tryrelish/relish-types";
import ProgramContext from "../../contexts/ProgramContext";
import ProgramStatusInput from "./ProgramStatusInput";
import LinksList from "../Link/LinksList";
import { useNavigate } from "react-router-dom";
import UserImage from "../elements/UserImage";


interface CreateOrUpdateProgramProps {
  existingProgram?: ProgramType;
  existingSite?: SiteType;
  existingUser?: UserType | null;
}

const newProgram: ProgramType = {
  referredReward: '',
  referrerReward: '',
  faqs: [],
  uiText: [],
  terms: '',
  siteRef: '' as Path,
  status: ProgramStatus.pending,
};

const newSite: SiteType = {
  name: '',
  displayName: '',
  topLevelDomains: [],
  hostname: '',
  domain: '',
  pathname: '',
};

const CreateOrUpdateProgram = ({
  existingSite = newSite,
  existingProgram = newProgram,
  existingUser = null,
}: CreateOrUpdateProgramProps) => {
  const { api, user: loggedInUser } = useContext(AuthContext);
  const programContext = useContext(ProgramContext);
  const [program, setProgram] = useState<ProgramType | null>(existingProgram);
  const [site, setSite] = useState<SiteType | null>(existingSite);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserType | null>(existingUser);

  const navigate = useNavigate();


  const updateProgramField = (updateData: Partial<ProgramType>) => {
    if (!program) {
      return;
    }

    const updatedProgram: ProgramType = {
      ...program,
      ...updateData,
    };
    setProgram(updatedProgram);
    setUser(loggedInUser);
  };

  const updateSiteField = (updateData: Partial<SiteType>) => {
    if (!site) {
      return;
    }

    const updatedSite: SiteType = {
      ...site,
      ...updateData
    };
    setSite(updatedSite);
  }

  const saveProgram = (p: ProgramType, s: SiteType, u: UserType | null) => {
    const changesExist = !isEqual(p, existingProgram);
    const okayToSave = p.siteRef && (p.referredReward || p.referrerReward);
    const modelsExist = p;
    if (modelsExist && changesExist && okayToSave) {
      setLoading(true);
      api
        ?.saveProgram(p)
        .then(() => {
          if (existingProgram) {
            programContext.setActiveProgram({ program: p, site: s, user: u });
          }
          toast.success("program updated")
        })
        .catch(() => toast.error("Failed to update program"))
        .finally(() => setLoading(false));
    }
  };

  if (!program || !site) {
    return <div />
  }
  return (
    <div className="link-tab mb-5">
      <Container>
        <div className="edit-section">
          <div className="back-arrow d-flex align-items-center">
            <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
              <img
                className="img-fluid me-3"
                src={BackArrow}
                alt="Back Arrow"
              />
            </div>
            <h4>Edit Program</h4>
          </div>
          <div className="tabs-programs bg-white p-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="Details">
              <div style={{ position: 'relative' }}>
                <Nav variant="pills" className="d-flex p-0 mb-4">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Details"
                      className="nav-tabbar px-0 me-4"
                    >
                      Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="MoreInfo" className="nav-tabbar px-0  me-4">
                      More Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Links" className="nav-tabbar px-0">
                      Links
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {existingProgram.id && <div style={{ position: 'absolute', right: 0, top: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {user && user.email && <div style={{ paddingRight: 15 }}><UserImage user={user}/></div>}
                    <ProgramStatusInput program={program} callback={updateProgramField} />
                  </div>
                </div>}
              </div>
              <ProgramPreview program={program} site={site} />
              <Tab.Content>
                <Tab.Pane eventKey="Details">
                  <Details
                    site={site}
                    program={program}
                    user={user}
                    saveProgram={(p: ProgramType, s: SiteType, u: UserType | null) => (e?: React.FormEvent) => {
                      if (e) {
                        e.preventDefault();
                      }
                      saveProgram({ ...p, lastModifiedBy: user?.path }, s, u);
                    }}
                    updateSiteField={updateSiteField}
                    updateProgramField={updateProgramField}
                    loading={loading}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="MoreInfo">
                  <MoreInfo
                    program={program}
                    updateProgramField={updateProgramField}
                    saveProgram={e => {
                      e.preventDefault();
                      saveProgram({ ...program, lastModifiedBy: user?.path }, site, loggedInUser);
                    }}
                    loading={loading}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="Links">
                  <LinksList currCompany={site.name} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default CreateOrUpdateProgram;
