/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { SiteType } from "@tryrelish/relish-types";
import { Table } from "react-bootstrap";
import Modal from "../elements/Modal";
import Edit from "../../assets/images/edit.png";
import AuthContext from "../../contexts/AuthContext";
import Loading from "react-loading";

interface SiteModalRowProps {
  s: SiteType;
  saveSite: (displayName: string, hostname: string, id: string) => void;
  saving: boolean;
  handleClose: () => void;
  updateProgramSite: (s: SiteType) => void
}

const SiteModalRow = ({
  saving,
  saveSite,
  s,
  updateProgramSite,
  handleClose,
}: SiteModalRowProps) => {
  const [editingRow, setEditingRow] = useState('');
  const [editedDisplayName, setEditedDisplayName] = useState('');
  const [editedDomain, setEditedDomain] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
  const fullDomain = s.pathname.length > 1 && !s.hostname.includes(s.pathname as string) ? s.hostname + s.pathname : s.hostname;
  return (
    <>
      {isEditing && editingRow === (s.id as string) ? (
        <tr key={s.name}>
          <td></td>
          <td>
            <input type="text" onChange={e => setEditedDisplayName(e.currentTarget.value)} value={editedDisplayName} />
          </td>
          <td>
            <input type="text" onChange={e => setEditedDomain(e.currentTarget.value)} value={editedDomain} />
          </td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <button
                className="button primary"
                onClick={() => {
                  saveSite(editedDisplayName, editedDomain, s.id!);
                  setEditingRow('');
                }}
                disabled={saving}
                style={{ 
                  padding: 0,
                  width: 20,
                  height: 40,
                  color: '#252525',
                  cursor: 'pointer',
                }}
              >
                &#10003;
              </button>
              <button
                className="button"
                style={{ padding: 0, width: 20, height: 40, color: '#252525', marginLeft: 5, cursor: 'pointer' }}
                onClick={() => setIsEditing(false)}
                disabled={saving}
              >
                &#215;
              </button>
            </div>
          </td>
        </tr>
      ) : (
        <tr key={s.name}>
          <td>
            <div>
              <button className="button" style={{ fontSize: 14, padding: 4, border: '1px solid', borderRadius: 6 }} onClick={() => {
                  updateProgramSite(s);
                  handleClose();
                }}>
                Select
              </button>
            </div>
          </td>
          <td>
            {s.displayName}
          </td>
          <td>
            <a href={fullDomain} target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>{fullDomain}</a>
          </td>
          <td>
            <span
              className="ms-2"
              onClick={() => {
                setEditingRow(s.id as string);
                setIsEditing(true);
                setEditedDisplayName(s.displayName);
                setEditedDomain(fullDomain);
              }}
            >
              <img src={Edit} alt="Edit" style={{ width: 25 }} />
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

interface SiteChangeModalProps {
  show: boolean;
  handleClose: () => void;
  updateProgramSite: (s: SiteType) => void,
}

const PAGE_SIZE = 10;

const SiteChangeModal = ({
  show,
  handleClose,
  updateProgramSite,
}: SiteChangeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sites, setSites] = useState<SiteType[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { api } = useContext(AuthContext);

  const siteInput = useRef<HTMLInputElement>(null);

  const getSites = useCallback(() => {
    setLoading(true);
    if (api) {
      api
        .getSites(PAGE_SIZE, (currentPage - 1) * PAGE_SIZE, name)
        .then(data => {
          setSites(data);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [api, name, currentPage]);

  const formElement = (
    <form onSubmit={e => {
      e.preventDefault();
      if (siteInput.current) {
        setName(siteInput.current.value);
        setCurrentPage(1);
        siteInput.current.blur();
      }
    }}>
      <input ref={siteInput} type="text" onBlur={e => {
        setIsSearching(false);
      }} />
    </form>
  );

  const getSiteSearch = () => {
    if (isSearching) {
      setTimeout(() => {
        if (siteInput.current) {
          siteInput.current.focus();
        }
      }, 5);
      return formElement;
    } else {
      if (name) {
        return (
          <div
            onClick={() => setName('')}
            style={{ textDecoration: 'underline' }}
          >
            {name}
          </div>);
      } else {
        return <span onClick={() => setIsSearching(true)}>Name</span>
      }
    }
  }

  const saveSite = (displayName: string, hostname: string, id: string) => {
    setSaving(true);
    if (api) {
      api.saveSite({ displayName, hostname, id }).then(({ updatedSite }) => {
        const editedSiteIndex = sites.findIndex(s => s.id === id);
        setSites([
          ...sites.slice(0, editedSiteIndex),
          updatedSite,
          ...sites.slice(editedSiteIndex + 1),
        ]);
      }).catch(console.log).finally(() => {
        setSaving(false);
      });
    }
  };

  useEffect(() => {
    getSites();
  }, [currentPage, name]);

  return (
    <Modal
        id="site-pick-modal"
        show={show}
        handleClose={handleClose}
        className="site-change-modal"
      >
        <Table className="bg-transparent mb-0">
          <thead>
            <tr className="position-relative">
              <th></th>
              <th style={{ textDecoration: 'underline' }}>{getSiteSearch()}</th>
              <th>Domain</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loading ? <Loading color="#252525" /> : sites.map(s =>  (
              <SiteModalRow
                key={s.id}
                saveSite={saveSite}
                s={s}
                saving={saving}
                updateProgramSite={updateProgramSite}
                handleClose={handleClose}
              />
            ))}
          </tbody>
        </Table>
      </Modal>
  );
};

export default SiteChangeModal;