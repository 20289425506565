// Import FirebaseAuth and firebase.
import React, { useContext, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Loading from 'react-loading';
import AuthContext from '../../contexts/AuthContext';
import FirebaseContext from '../../contexts/FirebaseContext';
import Button from '../elements/Button';

const SignInScreen = () => {
    const [showAuth, setShowAuth] = useState(false);
    const {
        auth,
        uiConfig,
    } = useContext(FirebaseContext);
    const { loading, user } = useContext(AuthContext);

    if (!auth || loading) {
        return (
            <div style={{ width: 100, textAlign: 'center' }}>
                <Loading color="#252525" type="spin" width="40px" height="40px" />
            </div>
        );
    }

    const loginCallback = () => {
        setShowAuth(false);
    };

    return (
        <div>
            {user ? (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ paddingRight: 8 }}>
                            Welcome {user.displayName.split(' ')[0]}
                        </div>
                        {user.profileImg && <img
                            style={{
                                width: 50,
                                borderRadius: '50%',
                                boxShadow: '0 2px 6px 0 rgb(0 0 0 / 15%)',
                            }}
                            src={user.profileImg} alt={user.displayName}
                            referrerPolicy="no-referrer"
                        ></img>}
                    </div>
                </div>

            ) : (
                <Button
                    className="button button-secondary button-wide-mobile button-md"
                    onClick={() => setShowAuth(!showAuth)}
                    style={{ padding: '6px 36px'}}
                >
                    Login
                </Button>
            )}
            {showAuth && (
                <div onClick={() => setShowAuth(false)} style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(255,255,255,0.75)'
                }}>
                    <div onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }} className="has-shadow" style={{
                        position: 'absolute',
                        top: '100px',
                        right: '0',
                        left: '0',
                        margin: '0 auto',
                        width: '360px',
                        backgroundColor: '#FDFDFD',
                        padding: '1em',
                        paddingBottom: '2em',
                        textAlign: 'center',
                        borderRadius: '6px',
                    }}>
                        <h4 style={{ paddingBottom: '1em' }}>Choose a sign-in method</h4>
                        <StyledFirebaseAuth uiConfig={uiConfig(loginCallback)} firebaseAuth={auth()} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default SignInScreen;
