import React from 'react';
import firebase from 'firebase/compat/app';
import { UserType } from '@tryrelish/relish-types';
import Api from '../utils/api';

type AuthContextModel = {
  loading: boolean,
  user: UserType | null,
  firebaseUser: firebase.User | null,
  setRelishCookie: () => Promise<void>,
  api: Api | null,
  // fromUser stores the user id that originated the request to the login page
  // It is set from the extension via a query parameter to allow mapping anonymous
  // users to new ones.
  fromUserId: string | null,
};


const AuthContext = React.createContext<AuthContextModel>({
  loading: true,
  user: null,
  setRelishCookie: () => Promise.reject(new Error('Context not loaded')),
  firebaseUser: null,
  api: null,
  fromUserId: null
});

export default AuthContext;
