import React, { useContext, useEffect, useState } from 'react';
import Loading from 'react-loading';
import { useParams } from 'react-router-dom';
import BlogItem from '../components/BlogItem';
import AuthContext from '../contexts/AuthContext';
import { BlogItemType } from '../types';
import { getIdFromGuid } from '../utils';

const BlogItemPage = () => {
  const [loading, setLoading] = useState(true);
  const [blogItems, setBlogItems] = useState<BlogItemType[]>([]);

  const { api } = useContext(AuthContext);

  useEffect(() => {
    const getBlogData = async (callback: (items: BlogItemType[]) => void) => {
      if (api) {
        const items = await api.getBlog();
        callback(items);
      }
    }
    void getBlogData(data => {
      setBlogItems(data);
      setLoading(false);
    });
  }, [api]);

  const params = useParams();

  if (loading) {
    return <div style={{ minHeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loading color='#252525' />
    </div>
  }

  const item = blogItems.find(({ guid }) => getIdFromGuid(guid) === params.id);

  if (!item) {
    return <div style={{ minHeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loading color='#252525' />
    </div>
  }

  return (
    <div style={{ minHeight: 400 }}>
      <BlogItem item={item} />
    </div>
  )
};

export default BlogItemPage;
