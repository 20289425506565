import React from "react";
import { ProgramsAndStuffType } from "../types";

type ProgramContextModel = {
  loading: boolean;
  programs: ProgramsAndStuffType[];
  activeProgram: ProgramsAndStuffType | null;
  setPrograms: (e: ProgramsAndStuffType[]) => void;
  setActiveProgram: (e: ProgramsAndStuffType) => void;
};

const ProgramContext = React.createContext<ProgramContextModel>({
  loading: false,
  programs: [],
  activeProgram: null,
  setActiveProgram: (e: ProgramsAndStuffType) => {},
  setPrograms: (e: ProgramsAndStuffType[]) => {},
});

export default ProgramContext;
