import React from 'react';
import classNames from 'classnames';
import { SectionSharedProps } from '../../utils/SectionProps';
import Cta from './Cta';
import PopupImg from './../../assets/images/popup.png';
import ChromeLogo from '../../assets/images/chrome.png';

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}: SectionSharedProps) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e: any) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  // const closeModal = (e: any) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // } 

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-max">
        <div className="container-xl">
          <div className={innerClasses}>
            <div className="split-2-1">
              <div className="hero-content">
                <h1 className="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
                  <div className="oversized">
                    Referral discounts
                  </div>
                  <div className="oversized" style={{ color: 'rgb(237, 121, 84)'}}>
                    while you shop
                    {/* <a href="https://www.joinhoney.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(237, 121, 84)'}}>
                      Honey
                    </a> */}
                  </div>
                </h1>
                <div className="hero-subtitle-content-container">
                  <h3 className="m-0 mb-32 reveal-from-bottom hero-subtitle" data-reveal-delay="400">
                    <span style={{ display: 'block' }}>
                     Relish is the Chrome extension for{" "}
                      <a href="https://medium.com/userelish/why-we-verify-referral-links-61391149952c" target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(237, 121, 84)'}}>
                        verified 
                      </a>
                    </span>
                    <span style={{ display: 'block' }}>
                      <a href="https://medium.com/userelish/why-we-verify-referral-links-61391149952c" target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(237, 121, 84)'}}>
                        referral links{" "}
                      </a>
                      to save big shopping online
                    </span>
                  </h3>
                  <a
                    className="hero-install button button-primary button-wide-mobile button-md"
                    href="https://chrome.google.com/webstore/detail/relish/ookcfeohfcfmpapmafpljepfljnlkdcn?hl=en"
                  >
                    <div style={{ position: 'relative', left: '-17px' }}>
                      <img src={ChromeLogo} alt="Chrome logo" style={{
                          paddingRight: 12,
                          height: '100%',
                          display: 'inline-block',
                          top: '-3px',
                          position: 'relative',
                        }} />
                      GET IT!
                    </div>
                  </a>
                  <div className="hero-detail-image-2">
                    <img src={PopupImg} alt="Save big!" style={{ border: '1px solid', borderRadius: 3 }} />
                    <div style={{ marginTop: '0.5rem', color: '#000', fontWeight: 'bold', fontSize: 16 }}>90 off for you, $40 off for them. It&apos;s that easy!</div>
                  </div>
                  <Cta />
                </div>
              </div>
              <div className="hero-detail-image">
                <img src={PopupImg} alt="Save big!" style={{ border: '1px solid', borderRadius: 3 }} />
                <div style={{ textAlign: 'center', fontSize: 16, paddingTop: 8, fontWeight: 'bold' }}>$90 off for you, $40 off for them. It&apos;s that easy!</div>
              </div>
            </div>
            
            {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
              <a
                data-video="https://player.vimeo.com/video/174002812"
                href="#0"
                aria-controls="video-modal"
                onClick={openModal}
              >
                <Image
                  className="has-shadow"
                  src={VideoPlaceholderImg}
                  alt="Hero"
                  width={896}
                  height={504} />
              </a>
            </div> */}
            {/* <Modal
              id="video-modal"
              show={videoModalActive}
              handleClose={closeModal}
              video="https://player.vimeo.com/video/174002812"
              videoTag="iframe" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;