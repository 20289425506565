import React, { useContext } from 'react';
import Loading from 'react-loading';
import {  Outlet } from "react-router-dom";
import AuthContext from '../contexts/AuthContext';

const CMS = () => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loading color="#252525" />
      </div>
    );
  }

  if (!user) {
    return <div>Must be logged in</div>
  }

  return (
        <>
           <Outlet />
        </>  
    )
  
  }
export default CMS;
