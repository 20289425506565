import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App2';
import Amazon from './AmazonApp';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from 'reactfire';
import './assets/scss/style.scss';
import { Toaster } from 'react-hot-toast';

const firebaseConfig = {
  apiKey: "AIzaSyASNmz6uMqJM_JINQ4wjhdnz4XvHc6k1jg",
  authDomain: "relish-referrals.firebaseapp.com",
  databaseURL: "https://relish-referrals-default-rtdb.firebaseio.com",
  projectId: "relish-referrals",
  storageBucket: "relish-referrals.appspot.com",
  messagingSenderId: "381560899562",
  appId: "1:381560899562:web:be9a3bc58f8b584dcb9493",
  measurementId: "G-6JNMMT6LDY"
};

const isAmazonApp = window.location.origin.includes('localhost') || window.location.origin.includes('amazon') || window.location.origin.includes('relish.club');
console.log(window.location.origin.includes('localhost'))

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <BrowserRouter>
      <Toaster />
      {isAmazonApp ? <Amazon /> : <App />}
    </BrowserRouter>
  </FirebaseAppProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
