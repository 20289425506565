import React, { useContext, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Loading from "react-loading";
import AuthContext from "../contexts/AuthContext";
import FirebaseContext from "../contexts/FirebaseContext";

function Login() {
  const { auth, uiConfigRedirect } = useContext(FirebaseContext);
  const [authError, setError] = useState<undefined | string>();
  const { loading, setRelishCookie, user } = useContext(AuthContext);
  const loginCallback = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setRelishCookie().then(() => {
      window.close();
    }).catch(error => {
      setError('Something went wrong. Please, try again in a few moments.');
      console.error('Error while authenticating', error);
    });
  };

  if (!auth || loading) {
    return (
      <div style={{ width: 100, textAlign: "center" }}>
        <Loading color="#252525" type="spin" width="40px" height="40px" />
      </div>
    );
  }
  if (user) {
    return <p>Welcome, {user.displayName}. You&apos;re already logged in.</p>
  }
  if (authError) {
    return <p>{authError}</p>
  }

  return (
    <div>
      <h4 style={{ paddingBottom: "1em" }}>Choose a sign-in method</h4>
      <StyledFirebaseAuth
        uiConfig={uiConfigRedirect(loginCallback)}
        firebaseAuth={auth()}
      />
    </div>
  );
}

export default Login;
