import React from 'react';
import Mission from '../../components/amazon/sections/Mission';
import Support from '../../components/sections/Support';
import Team from '../../components/sections/Team';

// import sections

const About = () => {
  return (
    <>
      <Mission />
      <Team />
      <Support />
    </>
  );
}

export default About;