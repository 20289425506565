import React, { useContext, useState } from 'react';
import { ProgramStatus, ProgramType, UserType } from '@tryrelish/relish-types';
import AuthContext from '../../contexts/AuthContext';
import Arrow from "../../assets/images/arrow.png";

interface ProgramStatusInputProps {
  program: ProgramType;
  callback?: (p: ProgramType, u: UserType | null) => void
}

const ProgramStatusInput = ({
  program,
  callback = () => {},
}: ProgramStatusInputProps) => {
  const [status, setStatus] = useState(program.status);
  const { api, user } = useContext(AuthContext);
  const updateStatus = (nextStatus: ProgramStatus) => {
    setStatus(nextStatus);
    api
      ?.saveProgram({ ...program, status: nextStatus, lastModifiedBy: user?.path })
      .then(({ program: p, error }) => {
        if (!p) {
          throw new Error(error || 'Unknown error');
        }
        callback(p, user);
      })
      .catch(error => console.error(error));
  };
  return (
    <div className="dropdown-select">
      <span>
        {status}{" "}
        <img className="arrow ms-2" src={Arrow} alt="arrow" />
      </span>
      <div className="open-dropdown">
        <ul className="p-0 m-0">
          {Reflect.ownKeys(ProgramStatus).map(k => (
            <li
              key={k as string}
              onClick={() =>
                updateStatus(k as ProgramStatus)
              }
              className={program.status === k ? "active" : ""}
            >
              {k as string}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProgramStatusInput;