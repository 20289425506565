import React from 'react';
import classNames from 'classnames';
import { SectionSharedProps } from '../../../utils/SectionProps';
import Cta from '../../sections/Cta';
import PopupImgFG from './../../../assets/images/alf-hero-popup.png';
import PopupImgBG from './../../../assets/images/alf-hero-fullscreen.png';
import Sparkles from './../../../assets/images/sparkles.png';

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}: SectionSharedProps) => {


  const outerClasses = classNames(
    'hero section amazon-hero',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-max">
        <div className="container-xl">
          <div className={innerClasses}>
            <div className="split-1-1">
              <div className="hero-content">
                {/* <h1 className="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
                  <div className="oversized" style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ color: '#C6B9F8' }}>My Link Fan</span>
                  </div>
                </h1> */}
                <div className="hero-subtitle-content-container">
                  <h1 className="m-0 mb-32 reveal-from-bottom hero-subtitle" data-reveal-delay="400">
                    <span style={{ display: 'block' }}>
                      Get Affiliate Income
                    </span>
                    <span style={{ display: 'block' }}>
                      from <span style={{ color: '#C6B9F8' }}>Every</span><span> Amazon Shopper</span>.
                    </span>
                  </h1>
                  <h4 style={{ color: '#fff', fontWeight: 300 }}>
                    <div>Join for free and boost your reach.</div>
                    <div>Shopping influencers never had it so easy.</div>
                  </h4>
                  <Cta amazon />
                  <div className="hero-detail-image-2" style={{ position: 'relative', marginTop: '2rem' }}>
                    <img src={Sparkles} alt="Woo!" style={{ borderRadius: 3, position: 'absolute', top: '-2rem', zIndex: 3 }} />
                    <img src={PopupImgFG} alt="Promote your brand" style={{ borderRadius: 3, position: 'relative', zIndex: 2 }} />
                  </div>
                </div>
              </div>
              <div className="hero-detail-image" style={{ position: 'relative' }}>
                <img src={Sparkles} alt="Woo!" style={{ borderRadius: 3, position: 'absolute', width: '85%', right: '-4rem', top: '-25%', zIndex: 1 }} />
                <img src={PopupImgBG} alt="Affiliate links as you shop" style={{ borderRadius: 15, position: 'absolute', width: '90%', right: '-2rem', top: '-3%', zIndex: 2 }} />
                <img src={PopupImgFG} alt="Promote your brand" style={{ borderRadius: 3, position: 'absolute', width: '75%', left: '0%', top: '33%', zIndex: 3 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;