import React, { useContext, useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import BackArrow from "../../assets/images/BackArrow.png";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import AuthContext from "../../contexts/AuthContext";
import { SiteType } from "@tryrelish/relish-types";

const AddSite = () => {
  const { api } = useContext(AuthContext);
  const [site, setSite] = useState<Partial<SiteType>>({});
  const [loading, setLoading] = useState(false);


  const saveSite = (e: React.FormEvent) => {
    e.preventDefault();
    const okayToSave = site?.displayName?.length && site.hostname?.length;
    if (okayToSave) {
      setLoading(true);
      api
        ?.saveSite({ displayName: site.displayName!, hostname: site.hostname! })
        .then(() => toast.success("Site saved"))
        .catch(() => toast.error("Failed to save site"))
        .finally(() => setLoading(false));
    }
  };

  const updateSiteField = (data: Partial<SiteType>) => {
    setSite({
      ...site,
      ...data,
    });
  };

  return (
    <div className="link-tab mb-5">
      <Container>
        <div className="edit-section">
          <div className="back-arrow d-flex align-items-center">
            <Link to="/cms/programs">
              <img
                className="img-fluid me-3"
                src={BackArrow}
                alt="Back Arrow"
              />
            </Link>
            <h4>Add Site</h4>
          </div>
          <Form onSubmit={saveSite}>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={site.displayName as string || ''}
                onChange={e => updateSiteField({ displayName: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={site.hostname}
                onChange={e => updateSiteField({ hostname: e.target.value })}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};
export default AddSite;
