import React from 'react';
import DavidImg from '../../assets/images/me.jpeg';

const Mission = () => (
  <section className="section mission-section">
    <div className="container mission-container">
      <div className="section-inner">
        <div className="mission-title">
          <h1
            className="ta-c mt-0 mb-32 reveal-from-bottom"
            data-reveal-delay="200"
          >
            {`Why We're Building Relish`}
          </h1>
          <div className="mission-subtitle">
            <div style={{ display: "flex" }}>
              <div className="mission-profile-img-wrapper">
                <img src={DavidImg} alt="David Witwer" />
              </div>
              <div className="mission-byline">
                <div>Dave Witwer, CEO</div>
                <div>April 2, 2022</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>This February, my parents and I missed out on $130.</p>
            <p>
              That month, they signed up for{" "}
              <a href="https://sunbasket.com/refer-a-friend">Sunbasket</a>.
              Sunbasket offers $40 for everyone you refer, and $90 for the
              person referred. What they didn&#39;t realize?{" "}
              <strong>I have Sunbasket too</strong>. If they had known about my
              referral link,{" "}
              <strong>we would have all kept some cash in our wallets</strong>.
              Instead, we left $130 on the table.
            </p>
            <p>
              <strong>I&#39;m sure this same thing has happened to you.</strong>{" "}
              How many times have you bought something without using a referral
              link? How many times could you have shared a referral link and
              earned some free cash? We&#39;ll never know.
            </p>
            <p>
              We do know that tens of thousands of companies have referral
              programs, but on average,{" "}
              <strong>only 2.3% of people use them</strong>.
            </p>
            <p>
              That&#39;s why we&#39;re building Relish --{" "}
              <strong>so no one misses out on free referral bucks again</strong>
              .
            </p>
            <div>
              The idea is simple: all the links in one place, shared by
              everyone.
              <ol>
                <li>
                  <strong>Install:</strong> Add our Chrome extension to your
                  browser for easy access
                </li>
                <li>
                  <strong>Browse:</strong> Go about your business like normal.
                  We&#39;ll notify you anytime you can use a referral link.
                </li>
                <li>
                  <strong>Earn:</strong> Use the links and save! Add your own
                  referral links as well -- when others use them, you&#39;ll get
                  rewards as well.
                </li>
              </ol>
            </div>
          </div>
          <div>
            <div>
              <h3>What&#39;s Next?</h3>
            </div>
            <div>
              <p>
                <strong>
                  We&#39;re officially launching our Chrome extension on June 1.{" "}
                </strong>
              </p>
              <p>
                With the extension, you&#39;ll instantly have access to
                thousands of referral codes for hundreds of websites. We&#39;ll
                do the hard work and let you know when there&#39;s one you can
                use.&nbsp;
                <strong>No more wasting time</strong> looking for a referral
                link. And remember, anytime someone uses your link, you&#39;ll
                get bonuses too.
              </p>
              <p>
                <strong>It gets better.</strong> If you&#39;re one of the first
                5,000 installs, we&#39;ll also throw in some extra{" "}
                <a href="/faqs">Juice</a> to get you going.
              </p>
              <div
                style={{
                  paddingTop: "1rem",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                <div>We&#39;d love to see you save with us.</div>
                <div>- Dave and the Relish team</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Mission;
