import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import HowItWorks from '../components/sections/HowItWorks';
import Overview from '../components/sections/Overview';
import WhyRelish from '../components/sections/WhyRelish';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <HowItWorks />
      <Overview />
      <WhyRelish />
      {/* <HowItWorks />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider /> */}
      <Cta />
    </>
  );
}

export default Home;