import React from 'react';
import classNames from 'classnames';

interface FormHintProps {
  children: React.ReactNode
  className?: string
  status: string
  [prop: string]: any
}

const FormHint = ({
  children,
  className,
  status,
  ...props
}: FormHintProps) => {

  const classes = classNames(
    'form-hint',
    status && `text-color-${status}`,
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      {children}
    </div>
  );
};

export default FormHint;