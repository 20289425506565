import { LinkType } from "@tryrelish/relish-types";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AuthContext from "../contexts/AuthContext";
import { UserStatsType } from "../types";

const Stats = () => {
  const { api } = useContext(AuthContext);
  const [userStats, setUserStats] = useState<UserStatsType[]>([]);
  const getStats = async () => {
    if (!api) {
      return;
    }
    const data = await api.getStats();
    setUserStats(data);
  }

  useEffect(() => {
    void getStats();
  }, []);

  return (
    <Container>
      <div>
        <div>
          <h4>Modifications (last 14 days)</h4>
        </div>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Programs</th>
              <th>Program Updatess</th>
              <th>Links</th>
              <th>Link Updates</th>
            </tr>
          </thead>
          <tbody>
            {userStats.map(({ user, programs, links, linkUpdates, programUpdates }) => (
              <tr key={user.email}>
                <td>{user.email}</td>
                <td><div style={{ color: 'green', fontWeight: 'bold' }}>{programs.length}</div></td>
                <td>{programUpdates}</td>
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion */}
                <td><div style={{ color: 'green', fontWeight: 'bold' }}>{(links as LinkType[]).length}</div></td>
                <td>{linkUpdates}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  )
};

export default Stats;
