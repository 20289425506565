import React from 'react';
import { ProgramType, SiteType } from "@tryrelish/relish-types";

const ProgramPreview = ({
  program,
  site
}: {
  program: ProgramType,
  site: SiteType,
}) => {

  if (!program) {
    return <div />;
  }

  const getReferralText = () => {
    const pieces = [];
    if (program.referredReward) {
      pieces.push(
        <span key="referred-reward">
          Use this link and get&nbsp;
          <span style={{ fontWeight: 'bold' }}>
            {program.referredReward}
          </span>
          .
        </span>
      );
    }
  
    if (program.referrerReward) {
      pieces.push(
        <span key="referrer-reward">
          &nbsp;The person who referred you gets&nbsp;
          <span style={{ fontWeight: 'bold' }}>
            {program.referrerReward}
          </span>
          !
        </span>
      );
    }
  
    return <span>{pieces}</span>;
  };

  return (
    <div>
      <div style={{ width: '50%' }}>
        <div style={{
          paddingBottom: '8px',
          fontSize: '18px',
          color: '#000',
          fontWeight: 'normal',
        }}>
          UI Preview
        </div>
        <div className="p-8 mb-16" style={{ border: '1px solid', borderRadius: '6px' }}>
          <div
            style={{
              padding: 8,
            }}
          >
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 20,
                textAlign: 'center',
                letterSpacing: '0.059em',
                textTransform: 'capitalize',
                color: '#252525',
                paddingBottom: 16
              }}
            >
              Referral Link Found on <span style={{ fontWeight: 'bold' }}>{site?.displayName || '...'}</span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>
                  {getReferralText()}
                  &nbsp;
                  <span
                    style={{
                      display: 'inline',
                      fontWeight: 400,
                      textAlign: 'center',
                      letterSpacing: '-0.02em',
                      textDecorationLine: 'underline',
                      color: '#3290FE',
                      cursor: 'pointer',
                    }}
                  >
                    Learn More
                  </span>
                </div>
                <button
                  style={{ width: 212, padding: 8, margin: '16px 0' }}
                >
                  Go To Referral Page
                </button>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    letterSpacing: '-0.02em',
                    color: '#3290FE',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Copy for Later
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProgramPreview;