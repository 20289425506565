import React, { useContext, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import BackArrow from "../../../assets/images/BackArrow.png";
import { Link } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import toast from "react-hot-toast";
import ProgramPicker from "../../Program/ProgramPicker";
import { ProgramsAndStuffType } from "../../../types";
// import toast from "react-hot-toast";

const AddLink = () => {
  const { api } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [programAndSite, setProgramAndSite] = useState<ProgramsAndStuffType | null>(null);
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (!api || !programAndSite || !referralLink) {
      return;
    }
    api
      .addAdminLink({
        siteRef: programAndSite.site.path,
        programRef: programAndSite.program.path,
        referralLink,
      })
      .then(() => {
        toast.success('Added new Link!');
      })
      .catch(() => toast.error('Failed to add link'))
      .finally(() => setLoading(false));
  };
  return (
    <div className="link-tab mb-5">
      <Container>
        <div className="edit-section">
          <div className="back-arrow d-flex align-items-center">
            <Link to="/cms/programs">
              <img
                className="img-fluid me-3"
                src={BackArrow}
                alt="Back Arrow"
              />
            </Link>
            <h4>Add Link</h4>
          </div>
          <div className="tabs-programs bg-white p-5">
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId="formBasicUser">
                <Form.Label>Referral Link</Form.Label>
                <input className="form-control" type="text" value={referralLink} onChange={e => setReferralLink(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicUser">
                {programAndSite ? (
                  <div key={programAndSite.program.id || programAndSite.program.siteRef} style={{
                    display: 'flex',
                    padding: '8px 16px',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #ccc',
                    color: 'black',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }} onClick={() => setProgramAndSite(null)}>
                    <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>
                      {programAndSite.site.displayName}
                    </div>
                    <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>
                      {programAndSite.site.hostname}
                    </div>
                    <div>{programAndSite?.program.status}</div>
                  </div>
                ) : (
                  <ProgramPicker onProgramPicked={(p: ProgramsAndStuffType) => setProgramAndSite(p)}/>
                )}
              </Form.Group>
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AddLink;
