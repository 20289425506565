import React from 'react';
import DavidImg from '../../../assets/images/me.jpeg';

const Mission = () => (
  <section className="section mission-section">
    <div className="container mission-container">
      <div className="section-inner">
        <div className="mission-title">
          <h1
            className="ta-c mt-0 mb-32 reveal-from-bottom"
            data-reveal-delay="200"
          >
            {`Why We're Building Cashback Links`}
          </h1>
          <div className="mission-subtitle">
            <div style={{ display: "flex" }}>
              <div className="mission-profile-img-wrapper">
                <img src={DavidImg} alt="David Witwer" />
              </div>
              <div className="mission-byline">
                <div>Dave W</div>
                <div>May 6, 2023</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Amazon has a problem.</p>
            <p>
              They offer influencers and associates commission on sales through affiliate links.&nbsp;
              But they {"don't"} pass any of those earnings back to the customer.&nbsp;
              <strong>{"That's"} why {"we're"} building Cashback Links</strong>.&nbsp;
            </p>
            <p>
              The idea is simple: <strong>We promote affiliate links and give you cash back when you use them.</strong>{" "}
              Shop on Amazon like normal, and {"we'll"} show you when an influencer recommends a product.&nbsp;
              Use their affiliate link and make a purchase and {"we'll"} give you <i>1% cash back on the purchase!</i>&nbsp;
            </p>
            <p>
              There are a million Amazon associates with millions more affiliate links,&nbsp;
              but on average, <strong>only 2.3% of people use them</strong>.&nbsp;
              Why are we missing out on all this free cash?&nbsp;
            </p>
            <p>
              That&#39;s why we&#39;re building Relish link --{" "}
              <strong>to make sure we all get commission when shopping online.</strong>
              .
            </p>
            <div>
              The idea is simple: all the links in one place, shared by
              everyone.
              <ol>
                <li>
                  <strong>Install:</strong> Add our Chrome extension to your
                  browser for easy access
                </li>
                <li>
                  <strong>Browse:</strong> Go about your business like normal.
                  We&#39;ll notify you anytime you can use an affiliate link.
                </li>
                <li>
                  <strong>Earn:</strong> Use the links and earn cash back!
                </li>
              </ol>
            </div>
          </div>
          <div>
            <div
              style={{
                padding: "1rem 0 2rem",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              <div>Sign up now and start earning cash back</div>
              <div>- Dave & the Cashback Links Team</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Mission;
