import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesDefaults, SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import HowItWorks1 from './../../assets/images/how-it-works-1.png';
import HowItWorks2 from './../../assets/images/how-it-works-2.png';
import HowItWorks3 from './../../assets/images/how-it-works-3.png';
import HowItWorks4 from './../../assets/images/how-it-works-4.png';
// import HowItWorks5 from './../../assets/images/how-it-works-5.png';

type ScreenDef = {
  title: string,
  subTitle: string,
  img: string,
  idx: number,
};

const HowItWorks = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertColor,
  pushLeft,
  ...props
}: SectionTilesProps = SectionTilesDefaults) => {

  const [activeScreen, setActiveScreen] = useState(0);

  const screens: ScreenDef[] = [
    {
      title: 'We give you referrals',
      subTitle: 'Just browse like normal. We\'ll let you know when you can use a link and save.',
      img: HowItWorks1,
      idx: 0,
    },
    {
      title: 'Use the links & get discounts',
      subTitle: 'Closed the popup? Find a link in the extension and track how many times you\'ve saved',
      img: HowItWorks2,
      idx: 1,
    },
    // {
    //   title: 'Use the link and earn rewards',
    //   subTitle: 'Use the referral link so both you and the referrer save -- we\'ll stay out of the way.',
    //   img: HowItWorks3,
    //   idx: 2,
    // },
    {
      title: 'Add your own links for rewards',
      subTitle: 'When shopping, you\'ll find your own links. Add them to Relish and we\'ll automatically share them for you!',
      img: HowItWorks4,
      idx: 2,
    },
    {
      title: 'Relish the win!',
      subTitle: 'Stack those referral bucks! We\'re a free app, so when the savings stack up feel free to give back 😁',
      img: HowItWorks3,
      idx: 3,
    },
  ]

  const outerClasses = classNames(
    'how-it-works section section-padded',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'how-it-works-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'How It Works',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="split-1-2 how-it-works-desktop">
            <div style={{ cursor: 'pointer' }}>
              {screens.map(screen => (
                <div
                  key={`${screen.idx}-${screen.title}`}
                  className={classNames(
                    'screen',
                    screen.idx === activeScreen && 'active-screen'
                  )}
                  style={{ position: 'relative' }}
                >
                  <h3
                    className={classNames(
                      screen.idx === activeScreen && 'text-color-secondary',
                      screen.idx === activeScreen && 'checklist__item-link--active',
                      'checklist__item-link',
                      )}
                    style={screen.idx === 0 ? { marginTop: 0, paddingRight: '2em' } : { paddingRight: '2em' }}
                    onClick={() => setActiveScreen(screen.idx)}
                  >
                    {screen.title}
                  </h3>
                  {screen.idx === activeScreen && <div style={{ paddingRight: '2em' }}>
                    {screen.subTitle}
                  </div>}
                </div>
              ))}
            </div>
            <div>
              <img src={screens[activeScreen].img} alt={screens[activeScreen].title} style={{ border: '1px solid', borderRadius: 3 }} />
              <div className="dots">
                {screens.map(({ idx }) => (
                  <div
                    key={`dot-${idx}`}
                    className={idx === activeScreen ? 'dot active-dot' : 'dot'}
                    onClick={() => setActiveScreen(idx)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="how-it-works-mobile">
            {screens.map(screen => (
                <div key={`${screen.idx}-${screen.title}`} className={classNames(
                  'screen',
                )}>
                  <h3>
                    {screen.title}
                  </h3>
                  <img src={screen.img} alt={screen.title} style={{ border: '1px solid', borderRadius: 3 }} />
                  <div className="ta-c mt-8">
                    {screen.subTitle}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;