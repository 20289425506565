import React from 'react';

const Support = () => {
  return (
    <section className="section team-section">
      <div className="container team-container">
        <div className="section-inner">
          <h1 className="ta-c mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
            Need help?
          </h1>
          <div style={{ textAlign: 'center', fontSize: 24, color: 'black' }}>
            &nbsp;
            <a href="mailto:dave@userelish.com" style={{ color: '#000', textDecoration: 'underline' }}>
              Email Dave
            </a>!
          </div>
        </div>
      </div>
    </section>
  )
};

export default Support;