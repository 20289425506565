import React from 'react';
import DOMPurify from 'dompurify';
import { BlogItemType } from '../types';
import { convertPubDate } from '../utils';
import { Link } from 'react-router-dom';

interface BlogItemProps {
  item: BlogItemType
}

const BlogItem = ({ item }: BlogItemProps) => (
  <section className="section mission-section" style={{ backgroundColor: '#fff'}}>
      <div className="container mission-container">
        <div className="section-inner">
          <div style={{ display: 'inline-block', paddingBottom: '1rem', position: 'relative', top: '-24px' }}>
            <Link to="/">Home</Link>
            &nbsp;&gt;&nbsp;
            <Link to="/blog">Blog</Link>
            &nbsp;&gt;&nbsp;
            <span>{item.title.slice(0, 22)}...</span>
          </div>
          <div className="">
            <h1 className="ta-c mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
              {item.title}
            </h1>
            <div className="ta-c">{item.creator} | {convertPubDate(item.pubDate)}</div>
          </div>
          <div style={{ padding: '2em 0' }}>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item['content:encoded'])}} />
          </div>
        </div>
      </div>
    </section>
);

export default BlogItem;