import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { LinkType, ProgramType, UserType } from "@tryrelish/relish-types";
import { Table } from "react-bootstrap";
import Edit from "../../assets/images/edit.png";
import Filter from "../../assets/images/filter.png";
import FilterDark from "../../assets/images/filter-dark.png";
import Loading from "react-loading";
import AuthContext from "../../contexts/AuthContext";
import ProgramContext from "../../contexts/ProgramContext";
import { useNavigate } from "react-router";
import { LinkStatus, ProgramStatus } from "@tryrelish/relish-types/dist/constants/status";
import { OwnersType, ProgramsAndStuffType } from "../../types";
import ProgramStatusInput from "./ProgramStatusInput";
import { isProgramComplete } from "../../utils";
import { withPagination } from "../providers/Pagination";
import PaginationContext from "../../contexts/PaginationContext";
import UserImage from "../elements/UserImage";


const PAGE_SIZE = 10;

const ProgramList = () => {
  const [loading, setLoading] = useState(true);
  const [programsAndStuff, setProgramsAndStuff] = useState<ProgramsAndStuffType[]>([]);
  const [filter, setFilter] = useState('');
  const [company, setCompany] = useState('');
  const [ownerSearch, setOwnerSearch] = useState('');
  const [isCompanySearch, setIsCompanySearch] = useState(false);
  const [owners, setOwners] = useState<OwnersType[]>([]);

  const { page, setPage } = useContext(PaginationContext);

  const companyInput = useRef<HTMLInputElement>(null);

  const { api } = useContext(AuthContext);
  const programContext = useContext(ProgramContext);

  const getProgramsAndStuff = useCallback(() => {
    setLoading(true);
    if (api) {
      api
        .getProgramsAndStuff(PAGE_SIZE, (page - 1) * PAGE_SIZE, company, ownerSearch, filter)
        .then(data => {
          setProgramsAndStuff(data);
          programContext.setPrograms(data);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [api, page, filter, company, ownerSearch]);

  const getOwners = useCallback(() => {
    setLoading(true);
    if (api) {
      api
        .getOwners()
        .then(data => {
          setOwners(data);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [api]);

  const filterPrograms = (status: string) => {
    setFilter(status);
  };

  useEffect(() => {
    getProgramsAndStuff();
  }, [page, filter, company, ownerSearch]);

  useEffect(() => {
    getOwners();
  }, [])

  if (!api || loading) {
    return (
      <div
        style={{
          minHeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading color="#252525" />
      </div>
    );
  }

  const formElement = (
    <form onSubmit={e => {
      e.preventDefault();
      if (companyInput.current) {
        setCompany(companyInput.current.value);
        setPage(1);
        companyInput.current.blur();
      }
    }}>
      <input ref={companyInput} type="text" onBlur={e => {
        setIsCompanySearch(false);
      }} />
    </form>
  );

  const getCompanySearch = () => {
    if (isCompanySearch) {
      setTimeout(() => {
        if (companyInput.current) {
          companyInput.current.focus();
        }
      }, 5);
      return formElement;
    } else {
      if (company) {
        return (
          <div
            onClick={() => {
              setCompany('');
              setPage(1);
            }}
            style={{ textDecoration: 'underline' }}
          >
            {company}
          </div>);
      } else {
        return <span onClick={() => setIsCompanySearch(true)}>Company</span>
      }
    }
  }

  const updateProgram = (i: number) => (p: ProgramType, u: UserType | null) => {
    setProgramsAndStuff([
      ...programsAndStuff.slice(0, i),
      {
        ...programsAndStuff[i],
        program: p,
        user: u,
      },
      ...programsAndStuff.slice(i + 1),
    ]);
  }

  return (
    <Table className="bg-transparent mb-0">
      <thead>
        <tr className="position-relative">
        <th>
            <div className="dropdown-select header-dropdown-select" style={{ width: 100 }}>
              <span>
                Owner{" "}
                <img className="arrow ms-2" src={Filter} alt="Filter" />
              </span>
              <div className="open-dropdown">
                <h6 className="mb-0 mt-2 pt-1">
                  <img
                    className="arrow me-1"
                    src={FilterDark}
                    alt="Filter"
                  />{" "}
                  Pick an owner
                </h6>
                <ul className="p-0 m-0">
                  {[{ name: 'Any', value: '' }, ...owners].map(owner => (
                    <li
                      key={owner.name}
                      onClick={() => setOwnerSearch(owner.value)}
                      className={ownerSearch === owner.value ? "active" : ""}
                    >
                      {owner.name.split(' ')[0]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </th>
          <th style={{ textDecoration: 'underline' }}>{getCompanySearch()}</th>
          <th>Referrer Reward</th>
          <th>Reffered Reward</th>
          <th># of Links</th>
          <th style={{ width: 150 }}>
            <div className="dropdown-select header-dropdown-select">
              <span>
                Status{" "}
                <img className="arrow ms-2" src={Filter} alt="Filter" />
              </span>
              <div className="open-dropdown">
                <h6 className="mb-0 mt-2 pt-1">
                  <img
                    className="arrow me-1"
                    src={FilterDark}
                    alt="Filter"
                  />{" "}
                  Status filter
                </h6>
                <ul className="p-0 m-0">
                  <li
                    onClick={() => filterPrograms("")}
                    className={filter === "" ? "active" : ""}
                  >
                    All
                  </li>
                  <li
                    onClick={() => filterPrograms(ProgramStatus.broken)}
                    className={filter === ProgramStatus.broken ? "active" : ""}
                  >
                    Broken
                  </li>
                  <li
                    onClick={() => filterPrograms(ProgramStatus.pending)}
                    className={filter === ProgramStatus.pending ? "active" : ""}
                  >
                    Pending
                  </li>
                  <li
                    className={filter === ProgramStatus.checked ? "active" : ""}
                    onClick={() => filterPrograms(ProgramStatus.checked)}
                  >
                    Checked
                  </li>
                  <li
                    className={filter === ProgramStatus.complete ? "active" : ""}
                    onClick={() => filterPrograms(ProgramStatus.complete)}
                  >
                    Complete
                  </li>
                </ul>
              </div>
            </div>
          </th>
          <th style={{ width: 100 }}></th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {programsAndStuff.map(({ program, site, user }, i) =>  (
          <RenderProgram
            updateProgram={updateProgram(i)}
            program={program}
            site={site}
            user={user}
            key={program.id}
          />
        ))}
      </tbody>
    </Table>
  );
};

type LinksCountProps = {
  id: string
};

const LinksCount = ({ id }: LinksCountProps) => {
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState<LinkType[]>([]);


  const { api } = useContext(AuthContext);
  const getLinksByProgramRef = useCallback(() => {
    setLoading(true);
    if (api) {
      api
        .getLinksByProgramId(id)
        .then(data => {
          setLinks(data );
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [api, id]);

  useEffect(() => {
    getLinksByProgramRef();
  }, []);

  const linksByType = {
    broken: links.filter(l => l.status === LinkStatus.Broken),
    checked: links.filter(l => l.status === LinkStatus.Checked),
    pending: links.filter(l => l.status === LinkStatus.Pending),
  };

  if (loading) {
    return <div style={{ width: 100 }} />
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ color: 'green', padding: '0 5px' }}>{linksByType.checked.length}</div>
      <div style={{ color: 'gray', padding: '0 5px' }}>{linksByType.pending.length}</div>
      <div style={{ color: 'red', padding: '0 5px' }}>{linksByType.broken.length}</div>
    </div>
  );
};

interface RenderProgramProps {
  updateProgram: (p: ProgramType, u: UserType | null) => void
}

const RenderProgram = ({ program, site, user, updateProgram }: ProgramsAndStuffType & RenderProgramProps) => {
  const programContext = useContext(ProgramContext);
  const navigate = useNavigate();
  const handleEditClick = () => {
    programContext.setActiveProgram({ program, site, user });
    navigate(`/cms/programs/${program.id as unknown as string}/edit`);
  };

  const incomplete = !isProgramComplete(program);
  return (
    <tr>
      <td><UserImage user={user} /></td>
      <td className="pl-2">
        {incomplete ? <span style={{ fontWeight: 'bold', paddingRight: '4px', color: 'orange' }}>&#9888;</span> : null}
        <span>{site.displayName}</span>
      </td>
      <td>{program.referrerReward}</td>
      <td>{program.referredReward}</td>
      <td>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <LinksCount id={program.id!} />
      </td>
      <td>
        <ProgramStatusInput program={program} callback={updateProgram} />
      </td>
      <td>
        <span
          className="btn btn-transparent p-0 edit"
          onClick={() => handleEditClick()}
        >
          Edit <img className="ms-2" src={Edit} alt="Edit" style={{ width: 25 }} />
        </span>
      </td>
    </tr>
  );
};
export default withPagination(ProgramList);
