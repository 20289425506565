import React, { useContext, useEffect, useState } from 'react';
import Loading from 'react-loading';
import BlogItemCard from '../components/BlogItemCard';
import BlogJSON from '../components/BlogJSON';
import AuthContext from '../contexts/AuthContext';
import { BlogItemType } from '../types';

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [blogItems, setBlogItems] = useState<BlogItemType[]>([]);

  const { api } = useContext(AuthContext);

  useEffect(() => {
    const getBlogData = async (callback: (items: BlogItemType[]) => void) => {
      if (api) {
        const items = await api.getBlog();
        callback(items);
      }
    }
    void getBlogData(data => {
      setLoading(false);
      setBlogItems(data);
    });
  }, [api]);

  if (!api) {
    return <div style={{ minHeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Loading color='#252525' />
    </div>
  }
  return (
    <section className="section mission-section">
      <div className="container mission-container">
        <div className="section-inner" style={{ minHeight: 600 }}>
          <div>
            <h1
              className="ta-c mt-0 mb-32 reveal-from-bottom"
              data-reveal-delay="200"
            >
              What&#39;s News
            </h1>
          </div>
          <div>
            <BlogJSON />
          </div>
          {!loading && blogItems.length ? (
            <div>
              {blogItems.map(item => (
                <div key={item.title} className="blog-item-wrapper">
                  <BlogItemCard item={item} />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ minHeight: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Loading color='#252525' />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Blog;
