import React from 'react';
import { Link } from 'react-router-dom';
import { BlogItemType } from '../types';
import { getIdFromGuid, getImageFromBody, convertPubDate } from '../utils';

interface BlogItemProps {
  item: BlogItemType
}

const BlogItemCard = ({ item }: BlogItemProps) => (
  <Link style={{ textDecoration: 'none' }} to={`/blog/${getIdFromGuid(item.guid)}`}>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#fff',
      boxShadow: '0 5px 10px rgba(154,160,185,.15), 0 15px 40px rgba(166,173,201,.25)',
      borderRadius: '12px',
      margin: '2rem 0'
    }}>
      <div style={{ padding: '1rem', paddingTop: 0, width: 600 }}>
        <h4>
          {item.title}
        </h4>
        <div>{item.creator}</div>
        <div>{convertPubDate(item.pubDate)}</div>
      </div>
      <div style={{ height: 230, width: 300, padding: 15 }}>
        <img
          width={200}
          src={getImageFromBody(item['content:encoded'])}
          alt={item.title}
          style={{
            maxHeight: '200px',
            width: 'auto',
            margin: '0 auto',
          }}
        />
      </div>
    </div>
  </Link>
);

export default BlogItemCard;