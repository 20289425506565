import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Logo from './partials/Logo';
import Login from '../auth/Login';
import { Link } from 'react-router-dom';
import ChromeLogo from '../../assets/images/chrome.png';

interface HeaderProps {
  navPosition?: string
  hideNav?: boolean
  hideSignin?: boolean
  bottomOuterDivider?: boolean
  bottomDivider?: boolean
  className?: string
}

const Header = ({
  className,
  navPosition = '',
  hideNav = false,
  hideSignin = false,
  bottomOuterDivider = false,
  bottomDivider = false,
  ...props
}: HeaderProps) => {

  const [isActive, setIsactive] = useState(false);

  const nav = useRef<HTMLElement | null>(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    if (nav && nav.current && nav.current.style) {
      document.body.classList.add('off-nav-is-active');
      const maxHeight = `${nav.current.scrollHeight}px`;
      nav.current.style.maxHeight = maxHeight;
      setIsactive(true);
    }
  }

  const closeMenu = () => {
    if (nav && nav.current && nav.current.style) {
      document.body.classList.remove('off-nav-is-active');
      nav.current && (nav.current.style.maxHeight = '');
      setIsactive(false);
    }
  }

  const keyPress = (e: KeyboardEvent) => {
    isActive && (e.keyCode || e.key) === 27 && closeMenu();
  }

  const clickOutside = (e: MouseEvent) => {
    if (!nav.current) return;
    if (!e.target || !(e.target instanceof Element)) return;
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container-max">
        <div className="container-xl">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
            <Logo />
            {!hideNav &&
              <>
                <button
                  ref={hamburger}
                  className="header-nav-toggle"
                  onClick={isActive ? closeMenu : openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={nav}
                  className={
                    classNames(
                      'header-nav',
                      isActive && 'is-active'
                    )}>
                  <div className="header-nav-inner">
                    {/* <ul className={
                      classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                      )}>
                      <li>
                        <Link to="#0" onClick={closeMenu}>Documentation</Link>
                      </li>
                    </ul> */}
                    {!hideSignin &&
                      <ul
                        className="list-reset header-nav-right"
                      >
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/blog">Our Blog</Link>
                        </li>
                        <li>
                          <Login />
                        </li>
                        <li>
                          <a
                            className="button button-primary button-wide-mobile button-md"
                            href="https://chrome.google.com/webstore/detail/relish/ookcfeohfcfmpapmafpljepfljnlkdcn?hl=en"
                          >
                            <img src={ChromeLogo} alt="Chrome logo" style={{ paddingRight: 12 }} />
                            INSTALL
                          </a>
                        </li>
                      </ul>}
                  </div>
                </nav>
              </>}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
