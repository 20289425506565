import React from 'react';
// import sections
import Hero from '../../components/amazon/sections/Hero';
import HowItWorks from '../../components/amazon/sections/HowItWorks';
import Cta from '../../components/sections/Cta';

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <HowItWorks />
      {/* <HowItWorks />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider /> */}
      <Cta amazon section />
    </>
  );
}

export default Home;