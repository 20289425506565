import React from 'react';

interface WithLayoutProps {
  component: () => JSX.Element,
  layout: ({ children }: { children: React.ReactNode }) => JSX.Element,
  [prop: string]: any
}

const WithLayout = ({
  component: Component,
  layout: Layout,
  ...rest
}: WithLayoutProps) => {
  const FixedLayout =
    Layout === undefined
      ? (props: React.PropsWithChildren<any>) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          <>{props?.children}</>
        )
      : Layout;

  return (
    <FixedLayout>
      <Component />
    </FixedLayout>
  );
}

export default WithLayout;
