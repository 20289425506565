import React, { ComponentType } from "react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import FirebaseContext, {
  uiConfig,
  uiConfigRedirect,
} from "../../contexts/FirebaseContext";
import { makeReactProvider } from "../../utils/makeReactProvider";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from "reactfire";
import { IS_DEV } from "../../utils";

const config = {
  apiKey: "AIzaSyASNmz6uMqJM_JINQ4wjhdnz4XvHc6k1jg",
  authDomain: "relish-referrals.firebaseapp.com",
  databaseURL: "https://relish-referrals-default-rtdb.firebaseio.com",
  projectId: "relish-referrals",
  storageBucket: "relish-referrals.appspot.com",
  messagingSenderId: "381560899562",
  appId: "1:381560899562:web:be9a3bc58f8b584dcb9493",
  measurementId: "G-6JNMMT6LDY",
};

const Firebase = makeReactProvider((Component: ComponentType) => {
  firebase.initializeApp(config);

  const { auth } = firebase;

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {})
      .catch(err => console.error(err));
  };

  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const functionsInstance = getFunctions(app);
  if (IS_DEV) {
    connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
    connectFunctionsEmulator(functionsInstance, "localhost", 5001);
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <FunctionsProvider sdk={functionsInstance}>
        <FirebaseContext.Provider
          value={{ signOut, auth, uiConfig, uiConfigRedirect }}
        >
          <Component />
        </FirebaseContext.Provider>
      </FunctionsProvider>
    </FirestoreProvider>
  );
});

export default Firebase;
