import React, { useContext, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import BackArrow from "../../assets/images/BackArrow.png";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import toast from "react-hot-toast";

const AddUIText = () => {
  const { api } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    api
      ?.addUiText({
        title,
        description,
      })
      .then(() => toast('UI text added!'))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <div className="link-tab mb-5">
      <Container>
        <div className="edit-section">
          <div className="back-arrow d-flex align-items-center">
            <Link to="/cms/programs">
              <img
                className="img-fluid me-3"
                src={BackArrow}
                alt="Back Arrow"
              />
            </Link>
            <h4>Add UI Text field</h4>
          </div>
          <div className="tabs-programs bg-white p-5">
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId="formBasicUser">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicUser">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  placeholder=""
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AddUIText;
