import React, { ComponentType, useCallback, useEffect, useState } from "react";
import "firebase/compat/auth";
import Cookies from "universal-cookie";
import firebase from "firebase/compat/app";
import AuthContext from "../../contexts/AuthContext";
import { makeReactProvider } from "../../utils/makeReactProvider";
import { UserType } from "@tryrelish/relish-types";
import { functionWrapper } from "../../utils/functionWrapper";
import { useFunctions } from "reactfire";
import Api from "../../utils/api";
import { CookieType } from "../../types";
import { connectAuthEmulator } from "firebase/auth";
import { IS_DEV } from "../../utils";
import { sendExtensionLoginAction } from "../../utils/extension";
import config from "../../utils/config";

type UserWithToken = { user: UserType, customToken: string };

const Auth = makeReactProvider((Component: ComponentType) => {
  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [fromUserId, setFromUserId] = useState<string | null>(null);
  const functions = useFunctions();

  useEffect(() => {
    if (IS_DEV) {
      connectAuthEmulator(firebase.app().auth(), 'http://localhost:9099');
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParamFromUserId = urlSearchParams.get('uid');
    setFromUserId(queryParamFromUserId);
  }, []);
  const getUser = useCallback(
    async (u: firebase.User) => {
      setLoading(true);
      const { data } = await functionWrapper<
        any,
        UserWithToken
      >(functions, "getUser", {
        uid: u.uid,
        userData: {
          profileImg: u.photoURL,
          email: u.email,
          displayName: u.displayName,
          uid: u.uid,
          provider: u.providerData[0]?.providerId
        },
        fromUid: fromUserId,
      });
      setLoading(false);
      return data;
    },
    [functions, fromUserId]
  );

  const api = new Api(functions);

  const setRelishCookie = useCallback(async () => {
    try {
      const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      const authenticatingUser = firebase.auth().currentUser;
      if (!authenticatingUser) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const cookies: CookieType = new Cookies() as unknown as CookieType;
      setFirebaseUser(authenticatingUser);
      const { user: dbUser, customToken } = await getUser(authenticatingUser);
      if (dbUser && customToken) {
        setUser(dbUser);
        // @ts-ignore
        cookies.set(config.relishCookieKey, customToken, { expires: oneYearFromNow });
        await sendExtensionLoginAction(config.relishCookieKey);
      }
    } catch (e: any) {
      console.log(`Error:`, e);
    } finally {
      setLoading(false);
    }
  }, [getUser]);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async userRes => {
        setLoading(true);
        if (!userRes) {
          setLoading(false);
          return;
        }
        await setRelishCookie();
      });
    return () => unregisterAuthObserver();
  }, [setRelishCookie]);

  return (
    <AuthContext.Provider value={{ setRelishCookie, loading, user, firebaseUser, api, fromUserId }}>
      <Component />
    </AuthContext.Provider>
  );
});

export default Auth;
