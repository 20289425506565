import React from 'react';
import classNames from 'classnames';

interface SectionHeaderProps {
  data: {
    title?: string | React.ReactNode,
    paragraph?: string
  }
  children?: React.ReactNode[]
  tag?: 'h1' | 'h2' | 'h3'
  className?: string
}
const SectionHeader = ({
  className,
  data,
  children = [],
  tag = 'h2',
  ...props
}: SectionHeaderProps) => {

  const classes = classNames(
    'section-header',
    className
  );

  const Component = tag;
  return (
    <>
      {(data.title || data.paragraph || children) &&
        <div
          {...props}
          className={classes}
        >
          <div className="container-md">
            {children && 
              <Component className={
                classNames(
                  'mt-0',
                  data.paragraph ? 'mb-16' : 'mb-0'
                )}>{children}</Component>
            }
            {data.title &&
              <Component className={
                classNames(
                  'mt-0',
                  data.paragraph ? 'mb-16' : 'mb-0'
                )}>{data.title}</Component>
            }
            {data.paragraph &&
              <p className="m-0">{data.paragraph}</p>
            }
          </div>
        </div>
      }
    </>
  );
};

export default SectionHeader;