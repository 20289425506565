import React from 'react';
import classNames from 'classnames';
import { SectionTilesDefaults, SectionTilesProps } from '../../../utils/SectionProps';
import SectionHeader from '../../sections/partials/SectionHeader';
import Join from '../../../assets/images/join.png';
import Promote from '../../../assets/images/promote.png';
import GetPaid from '../../../assets/images/get-paid.png';

type ScreenDef = {
  title: string | JSX.Element,
  subTitle: string,
  idx: number,
  image: string,
};

const HowItWorks = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertColor,
  pushLeft,
  ...props
}: SectionTilesProps = SectionTilesDefaults) => {

  const screens: ScreenDef[] = [
    {
      title: 'Join',
      subTitle: 'Any Amazon influencers around? Just sign up with our 3-question form. We\'ll do all the heavy lifting.',
      idx: 0,
      image: Join,
    },
    {
      title: 'Promote',
      subTitle: 'Build you brand like you always do. Relish users will see your brand on Amazon when they shop.',
      idx: 1,
      image: Promote,
    },
    {
      title: 'Get Paid',
      subTitle: 'When any user completes a purchase, we\'ll give them cashback and you earn commission!',
      idx: 2,
      image: GetPaid,
    },
  ]

  const outerClasses = classNames(
    'how-it-works amazon-how-it-works section section-padded',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'how-it-works-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    // title: 'How It Works',
    title: <span><span style={{ color: '#7657EE' }}>How It Works</span></span>,
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} style={{  }}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div style={{alignItems: 'flex-start', flexDirection: 'row' }} className="how-it-works-desktop">
            {/* <h5>We send shoppers directly to your affiliate link from an Amazon product page.</h5>
            <h5>If they complete a purchase and earn you a commission, we&apos;ll reward them with a cashback of <b>1%</b> of the purchase price.</h5>
            <h5>Partner now and put your affiliate links to work!</h5> */}
            {screens.map(screen => (
              <div
                key={screen.idx}
                className={classNames(
                  'screen',
                )}
                style={{ position: 'relative', border: 0, paddingLeft: 0, textAlign: 'center', flexBasis: '33%', padding: '0 0.5rem' }}
              >
                <h4
                  className={classNames(
                    '',
                    )}
                    style={{ whiteSpace: 'nowrap', fontWeight: 600, fontSize: '22px' }}
                >
                  {screen.title}
                </h4>
                <div>
                  {screen.subTitle}
                </div>
                <div>
                  <img src={screen.image} alt="Woo!" style={{ borderRadius: '15px', margin: '0 auto', paddingTop: '2rem' }} />
                </div>
              </div>
            ))}
          </div>
          <div className='how-it-works-mobile'>
            {screens.map(screen => (
                <div
                  key={screen.idx}
                  className={classNames(
                    'screen',
                  )}
                  style={{ position: 'relative', border: 0, paddingLeft: 0, textAlign: 'center', flexBasis: '33%', padding: '0 0.5rem' }}
                >
                  <h3
                    className={classNames(
                      '',
                      )}
                  >
                    {screen.title}
                  </h3>
                  <div>
                    {screen.subTitle}
                  </div>
                  <div>
                    <img src={screen.image} alt="Woo!" style={{ borderRadius: '15px', margin: '0 auto', paddingTop: '2rem' }} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;