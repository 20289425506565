import { debounce } from 'lodash';
import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Loading from 'react-loading';
import { PAGE_SIZE } from '../../constants';
import AuthContext from '../../contexts/AuthContext';
import Api, { DocSearchEndpoint } from '../../utils/api';

interface DocSearchProps<T = Awaited<ReturnType<Api[DocSearchEndpoint]>>> {
  searchEndpoint: DocSearchEndpoint;
  renderDoc: (doc: T) => ReactNode;
  title: string;
  subTitle: string;
}

const DocSearch = <T = Awaited<ReturnType<Api[DocSearchEndpoint]>>>({
  searchEndpoint,
  renderDoc,
  title,
  subTitle,
}: DocSearchProps<T>) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [docs, setDocs] = useState<T[]>([]);
  const { api } = useContext(AuthContext);

  const docInput = useRef<HTMLInputElement>(null);

  const getDocs = async () => {
    if (!api || !search) {
      return;
    }
    setLoading(true);
    const fetchedDocs = await api[searchEndpoint](PAGE_SIZE, 0, search) as unknown;
    const fetchedDocsAsT = fetchedDocs as T[];
    setDocs(fetchedDocsAsT);
    setLoading(false);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getDocs();
  }, [search]);

  const updateValue = () => {
    if (docInput.current) {
      throttledSetValue(docInput.current.value);
    }
  }

  const throttledSetValue = useMemo(
    () => debounce((v: string) => (setSearch(v)), 500),
    [setSearch],
  );

  const preventSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
  }
  
  return (
    <Form.Group>
      <Form.Label>{title}</Form.Label>
      <input type="text" onChange={updateValue} className="form-control" ref={docInput} onKeyDown={preventSubmit} placeholder="Search" />
      {loading ? (
        <Loading color="#252525" />
      ) : (
        <div style={{ marginTop: '1em' }}>
          <div style={{ fontSize: 16, color: 'black' }}>{subTitle}</div>
          {docs.length ? docs.map(renderDoc) : <div>Search by name to find a doc</div>}
        </div>
      )}
    </Form.Group>
  );

};

export default DocSearch;