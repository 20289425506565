import React from 'react';
import classNames from 'classnames';
import { SectionTilesDefaults, SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Surfer from './../../assets/images/relish-surfer.png';

const WhyRelish = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertColor,
  pushLeft,
  ...props
}: SectionTilesProps = SectionTilesDefaults) => {
  const outerClasses = classNames(
    'why-relish section section-padded',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={{}} className="center-content">
            Relish is <a style={{ color: 'rgb(237, 121, 84)', fontWeight: 'bold' }} href="https://joinhoney.com">Honey for Referral Links</a>
          </SectionHeader>
          <div className="">
            <div className="how-it-works-description">
              <div>
                Thousands of companies offer referral discounts to new customers, but <strong>finding a referral link is too much work</strong>.
              </div>
              <div>
                We built Relish so you can instantly get <strong>trusted and verified</strong> referral links when you&apos;re shopping online.{" "}
                We&apos;ll give you a link when you land on a site -- click it to get the reward and save!
              </div>
            </div>
            {/* <div>
              <img src={Surfer} alt="Save with Relish" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyRelish;
