import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import CtaAction from './partials/CtaAction';
import AuthContext from '../../contexts/AuthContext';
import Loading from 'react-loading';
import Button from '../elements/Button';

const Cta = ({ amazon, section }: { amazon?: boolean, section?: boolean } = { amazon: false, section: false }) => {
  const [showCta, setShowCta] = useState(true);
  const [emailValue, setEmailValue] = useState('');

  const { api } = useContext(AuthContext);

  if (!api) {
    return <Loading />
  }


  const handleSubscribeUser = async () => {
    const { success, error } = await api.subscribeUser(emailValue);
    if (success) {
      setEmailValue('');
      setShowCta(false);
    } else {
      console.log(error);
    }
  };

  const onCTASubmit = (e: React.FormEvent) => {
    e.preventDefault();
    void handleSubscribeUser();
  };

  const classes = classNames(
    'hero-cta', amazon && 'amazon-cta',
  );

  const getCTAContent = () => {
    if (amazon) {
      return (
        <div style={{ padding: '2rem 0' }}>
          <a href="https://9rzx6xg9nxb.typeform.com/to/zXV8QyTB" target="_blank" rel="noreferrer" style={{ display: 'block' }}>
            <Button
              className="button button-secondary button-wide-mobile button-md amazon-cta-button"
            >
              <span style={{ color: '#7657EE' }}>Get Started</span>
            </Button>
          </a>
        </div>
      )
    } else if (showCta) {
      return (
        <div className={classNames(classes)}>
          <div className={classNames('hero-cta-title')}>
            <h3 style={amazon ? { color: '#FF9900' } : {}}>
              {amazon ? 'Partner now!' : 'Follow our updates!'}
            </h3>
          </div>
          <div style={{ flexGrow: 1, position: 'relative', top: 5, maxWidth: 500 }}>
            <form onSubmit={onCTASubmit}>
              <CtaAction
                value={emailValue}
                onChange={e => setEmailValue(e.currentTarget.value)}
                onSubmit={onCTASubmit}
              />
            </form>
          </div>
        </div>
      )
    }

    return (
      <div className={classNames('hero-cta')} style={amazon ? { justifyContent: 'center' } : {}}>
        <div className={classNames('hero-cta-title')}>
          <h3 style={amazon ? { fontSize: '1.1rem' } : {}}>
            {`Thanks for following :) We'll be in touch.`}
          </h3>
        </div>
      </div>
    );
  }

  if (section) {

    // const sectionHeader = {
    //   title: <span><span style={{ color: '#fff' }}>Expand your reach to every Amazon Shopper</span></span>,
    //   // paragraph: 'Fill out our quick, 3-question form and become a flagship partner for free.'
    // };

    return (
      <section
        className={classNames("section-padded", amazon && "amazon-cta")}
        style={{ backgroundColor: '#7657EE', color: '#fff', textAlign: 'center' }}
      >
        <div className="container">
          <div>
            <h2 style={{ margin: 0, paddingTop: '2rem', color: '#fff' }}>Be seen by every Amazon shopper.</h2>
            <h4 style={{ color: '#fff', fontWeight: 300, marginBottom: 0 }}>
              <div>More eyeballs. More fans.</div>
              <div>More money in your pocket.</div>
            </h4>
          </div>
          <div style={{ textAlign: 'center' }}>
            {getCTAContent()}
          </div>
        </div>
      </section>
    )
  }

  return (
    <div className="reveal-from-bottom" data-reveal-delay="600">
      {getCTAContent()}
    </div>
  );
};

export default Cta;
