import { FaqType, ProgramType, UiTextType } from "@tryrelish/relish-types";
import { debounce } from "lodash";
import React, { useMemo, useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import AuthContext from "../../contexts/AuthContext";
import { ProgramFaqType, ProgramUiTextType } from "../../types";

type FaqResponse = {
  faqs: FaqType[];
};
type UiResponse = {
  uitext: FaqType[];
};

interface MoreInfoProps {
  program: ProgramType;
  updateProgramField: (updateData: Partial<ProgramType>) => void;
  saveProgram: (e: React.FormEvent) => void;
  loading: boolean;
}

interface FaqInputProps {
  faq: FaqType;
  programFaq: ProgramFaqType;
  setValue: (v: string) => void;
}

interface UiTextTypeProps {
  uiText: UiTextType;
  programUiText: ProgramUiTextType;
  setValue: (v: string) => void;
}


interface NotesInputProps {
  notes: string;
  setValue: (v: string) => void; 
}

const FaqInput = ({ faq, programFaq, setValue }: FaqInputProps) => {
  const faqInput = useRef<HTMLInputElement>(null);

  const throttledSetValue = useMemo(
    () => debounce((v: string) => (setValue(v)), 500),
    [setValue],
  );

  const updateValue = () => {
    if (faqInput.current) {
      throttledSetValue(faqInput.current.value);
    }
  }
  return (
    <Form.Group key={faq.id} className="mb-3" controlId="formBasicUser">
      <Form.Label style={{ fontSize: 16 }}>{faq.title}</Form.Label>
      <div style={{ fontSize: 14 }}>{faq.description}</div>
      <Form.Control
        type="text"
        placeholder=""
        defaultValue={programFaq ? programFaq.value : ''}
        ref={faqInput}
        onChange={updateValue}
      />
    </Form.Group>
  )
}

const UITextInput = ({ uiText, programUiText, setValue }: UiTextTypeProps) =>  {
  const uiTextInput = useRef<HTMLInputElement>(null);

  const throttledSetValue = useMemo(
    () => debounce((v: string) => (setValue(v)), 500),
    [setValue],
  );

  const updateValue = () => {
    if (uiTextInput.current) {
      throttledSetValue(uiTextInput.current.value);
    }
  }
  return (
    <Form.Group className="mb-3" controlId="formBasicUser">
      <Form.Label style={{ fontSize: 16 }}>{uiText.title}</Form.Label>
      <div style={{ fontSize: 14 }}>{uiText.description}</div>
      <Form.Control
        type="text"
        placeholder=""
        defaultValue={programUiText ? programUiText.value : ''}
        ref={uiTextInput}
        onChange={updateValue}
      />
    </Form.Group>
  )
}

const NotesInput = ({ notes, setValue }: NotesInputProps) => {
  const notesInput = useRef<HTMLTextAreaElement>(null);

  const throttledSetValue = useMemo(
    () => debounce((v: string) => (setValue(v)), 500),
    [setValue],
  );

  const updateValue = () => {
    if (notesInput.current) {
      throttledSetValue(notesInput.current.value);
    }
  }
  return (
    <Form.Group className="mb-3" controlId="notes">
        <Form.Label>Notes</Form.Label>
        <Form.Control as="textarea" rows={3} onChange={updateValue} defaultValue={notes} ref={notesInput} />
      </Form.Group>
  )
};

const MoreInfo = ({ saveProgram, updateProgramField, program, loading }: MoreInfoProps) => {
  const { api } = useContext(AuthContext);

  const [faqs, setFaqs] = useState<FaqType[]>([]);
  const [uiTexts, setUiTexts] = useState<UiTextType[]>([]);
  useEffect(() => {
    api
      ?.getFaqs()
      .then((res: FaqResponse) => setFaqs(res.faqs))
      .catch(err => console.error(err));
    api
      ?.getUiText()
      .then((res: UiResponse) => setUiTexts(res.uitext))
      .catch(err => console.error(err));
  }, []);

  const updateFaq = (p: string) => (v: string) => {
    const currentFaqIndex = program.faqs.findIndex(({ ref }) => ref === p);
    const newFaqs: ProgramFaqType[] = currentFaqIndex >= 0 ? [
      ...program.faqs.slice(0, currentFaqIndex),
      { ref: p, value: v },
      ...program.faqs.slice(currentFaqIndex + 1),
    ] : [
      ...program.faqs,
      { ref: p, value: v },
    ];
    updateProgramField({
      faqs: newFaqs,
    });
  };

  const updateUiText = (p: string) => (v: string) => {
    const currentUiTextIndex = program.uiText.findIndex(({ ref }) => ref === p);
    const newUiTexts: ProgramUiTextType[] = currentUiTextIndex >= 0 ? [
      ...program.uiText.slice(0, currentUiTextIndex),
      { ref: p, value: v },
      ...program.uiText.slice(currentUiTextIndex + 1),
    ] : [
      ...program.uiText,
      { ref: p, value: v },
    ];
    updateProgramField({
      uiText: newUiTexts,
    });
  };

  const updateNotes = (v: string) => {
    updateProgramField({
      notes: v,
    });
  }

  return (
    <Form onSubmit={saveProgram}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Faqs</Accordion.Header>
          <Accordion.Body className="px-0">
            {faqs.map(faq => (
              <FaqInput
                key={faq.id || faq.title}
                faq={faq}
                programFaq={program.faqs.find(
                  ({ ref }) => ref === faq.path
                ) || { ref: faq.path as string, value: '' }}
                setValue={updateFaq(faq.path as string)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>UI Text</Accordion.Header>
          <Accordion.Body className="px-0">
            {uiTexts.map(uiText => (
              <UITextInput
                key={uiText.id || uiText.title}
                uiText={uiText}
                programUiText={program.uiText.find(
                  ({ ref }) => ref === uiText.path
                ) || { ref: uiText.path as string, value: '' }}
                setValue={updateUiText(uiText.path as string)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <NotesInput notes={program.notes as string} setValue={updateNotes} />
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? "Saving..." : "Save"}
      </Button>
    </Form>
  );
};
export default MoreInfo;
