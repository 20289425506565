import React from 'react';
import Input from '../../elements/Input';

interface CtaActionProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> & React.ChangeEventHandler<HTMLInputElement>,
  onSubmit?: (e: React.MouseEvent | React.FormEvent) => void,
  value: string,
}

const CtaAction = ({
  onChange,
  onSubmit,
  value,
}: CtaActionProps) => (
  <div className="cta-action">
    <div className="form-submit" onClick={e => onSubmit && onSubmit(e)}>
      <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#59C994" />
      </svg>
    </div>
    <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="your@email.com" onChange={onChange} onSubmit={onSubmit} value={value}>
    </Input>
  </div>
);

export default CtaAction;