import React from "react";


export type PaginationContextProps = {
  page: number;
  setPage: (page: number) => void;
  hasNext: boolean;
  setHasNext: (hn: boolean) => void;
}

const PaginationContext = React.createContext<PaginationContextProps>({
  page: 1,
  setPage: (page: number) => {},
  hasNext: true,
  setHasNext: (hn: boolean) => {},
});

export default PaginationContext;
