import { ProgramType } from "@tryrelish/relish-types";

export const IS_DEV = window.location.origin.includes('localhost')
export const WITH_AUTH = !process.env.REACT_APP_ONLY_FUNCTIONS

export const getIdFromGuid = (guid: string): string => {
  const split = guid.split('/');
  return split[split.length - 1];
};

export const getImageFromBody = (body: string) => {
  const imgStart = body.slice(body.indexOf('<img'));
  const imgSnippet = imgStart.slice(0, imgStart.indexOf('>'));
  const srcStart = imgSnippet.slice(imgSnippet.indexOf('src="')).slice(5);
  return srcStart.slice(0, srcStart.indexOf('"'));
};

export const convertPubDate = (d: string): string => d.split(' ').slice(0, 4).join(' ');

export const isProgramComplete = (p: ProgramType): boolean => (
  Boolean(
    p.siteRef
    && (p.referredReward || p.referrerReward)
  )
);
