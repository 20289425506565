import React, { useRef, useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { flow } from "lodash";
import ScrollReveal, { ScrollRevealHandleProps } from "./utils/ScrollReveal";
import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

// Layout
import WithLayout from "./utils/WithLayout";
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutDefaultIndex from "./layouts/LayoutDefaultIndex";

// Providers
import Auth from "./components/providers/Auth";
import Firebase from "./components/providers/Firebase";
import { default as ProgramProvider } from "./components/providers/Program";

// Views
import Home from "./views/Home";
import About from "./views/About";
import ScrollToTop from "./ScrollToTop";
import Blog from "./views/Blog";
import BlogItemPage from "./views/BlogItemPage";

// Components
import LinksList from "./components/Link/LinksList/index";
import ProgramList from "./components/Program/index";
import EditProgram from "./components/Program/EditProgram";
import AddFaq from "./components/Program/AddFaq";
import AddUIText from "./components/Program/AddUIText";
import Login from "./views/Login";
import AddLink from "./components/Link/AddLink";
import AddProgram from "./components/Program/AddProgram";
import AddSite from "./components/Program/AddSite";
import CMS from "./views/CMS";
import Privacy from "./views/Privacy";
import Stats from "./components/Stats";

// Initialize Google Analytics
ReactGA.initialize("G-6JNMMT6LDY");

const trackPage = (page: string) => {
  ReactGA.set({ page });
  ReactGA.send({ hitType: "pageview", page });
};

const App = () => {
  const childRef = useRef<ScrollRevealHandleProps | null>(null);
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    if (childRef.current) {
      childRef.current.init();
    }
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal ref={childRef}>
      {() => (
        <>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={<WithLayout component={Home} layout={LayoutDefault} />}
            />
            <Route
              path="/about"
              element={<WithLayout component={About} layout={LayoutDefault} />}
            />
            <Route
              path="/privacy"
              element={<WithLayout component={Privacy} layout={LayoutDefault} />}
            />
            <Route
              path="/blog"
              element={<WithLayout component={Blog} layout={LayoutDefault} />}
            />
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/blog/:id"
              element={
                <WithLayout component={BlogItemPage} layout={LayoutDefault} />
              }
            />
            <Route
              path="/cms"
              element={
                <WithLayout component={CMS} layout={LayoutDefaultIndex} />
              }
            >
              <Route
                path="/cms/stats"
                element={<Stats />}
              />
              <Route
                path="/cms/programs"
              >
                <Route index element={<ProgramList />} />
                <Route path=":id/edit" element={<EditProgram />} />
                <Route path="faqs/add" element={<AddFaq />} />
                <Route path="ui-text/add" element={<AddUIText />} />
                <Route path="program/add" element={<AddProgram />} />
                <Route path="site/add" element={<AddSite />} />
              </Route>
              <Route
                path="/cms/links"
              >
                <Route index element={<LinksList />} />
                <Route path="link/add" element={<AddLink />} />
              </Route>
            </Route>
            <Route
              path="/programs"
              element={
                <Navigate to="/cms/programs" />
              }
            />
            <Route
              path="/links"
              element={
                <Navigate to="/cms/links" />
              }
            />
          </Routes>
        </>
      )}
    </ScrollReveal>
  );
};

const providers: [typeof Auth, typeof Firebase, typeof ProgramProvider] = [
  Auth,
  Firebase,
  ProgramProvider,
];

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const ProvidedApp = flow(...providers)(App);

export default ProvidedApp;
