/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from "react";
import { ProgramType, SiteType, UserType } from "@tryrelish/relish-types";
import { Form, Button, Row, Col } from "react-bootstrap";
import Edit from "../../assets/images/edit.png";
import SiteChangeModal from "./SiteChangeModal";

interface DetailsProps {
  program: ProgramType;
  site: SiteType;
  user: UserType | null;
  updateProgramField: (updateData: Partial<ProgramType>) => void;
  saveProgram: (p: ProgramType, s: SiteType, u: UserType | null) => (e?: React.FormEvent) => void;
  loading: boolean;
  updateSiteField: (updateData: Partial<SiteType>) => void;
}

const Details = ({
  program,
  site,
  user,
  saveProgram,
  updateProgramField,
  loading,
}: DetailsProps) => {
  const [siteModalOpen, setSiteModalOpen] = useState(false);
  const [activeSite, setActiveSite] = useState(site);

  if (!program || !site) {
    return (
      <div>
        Please select a program
      </div>
    );
  }

  const updateProgramSite = (s: SiteType) => {
    console.log('Setting site', s.displayName);
    const p = {
      ...program,
      siteRef: s.path!,
    };
    saveProgram(p, s, user)();
    updateProgramField({ siteRef: s.path! });
    setActiveSite(s);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
  const fullDomain = site.pathname.length > 1 && !site.hostname.includes(site.pathname as string) ? site.hostname + site.pathname : site.hostname;

  return (
    <div>
      <Form onSubmit={saveProgram(program, activeSite, user)}>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>
            Company <b>*</b>
          </Form.Label>
          <Row>
            <Col lg={4}>
              <div onClick={() => setSiteModalOpen(true)}>{activeSite.displayName}</div>
            </Col>
            <Col lg={7}>
              <a
                style={{
                  color: '#626b74',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '500px',
                  display: 'inline-block',
                }}
                href={fullDomain}
                target="_blank"
                rel="noopener noreferrer"
              >
                {fullDomain}
              </a>
            </Col>
            <Col lg={1}>
              <img src={Edit} alt="Edit" style={{ width: 25, cursor: 'pointer' }} onClick={() => setSiteModalOpen(true)} />
            </Col>
          </Row>
          {/* <Button variant="outline-primary d-flex mt-3 px-0">
          <img className="me-2" width={20} src={AddFaq} alt="Add FAQ" /> Add
        </Button> */}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Program Terms Link</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.termsLink as string || ''}
            onChange={e => updateProgramField({ termsLink: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Terms</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.terms}
            onChange={e => updateProgramField({ terms: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Expiration</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.expiration as string}
            onChange={e => updateProgramField({ expiration: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Referrer Reward</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.referrerReward}
            onChange={e => updateProgramField({ referrerReward: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Reffered Reward</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.referredReward}
            onChange={e => updateProgramField({ referredReward: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Restrictions</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.restrictions as string || ''}
            onChange={e => updateProgramField({ restrictions: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Limit</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={program.limit as string || ''}
            onChange={e => updateProgramField({ limit: e.target.value })}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </Form>
      <SiteChangeModal show={siteModalOpen} handleClose={() => setSiteModalOpen(false)} updateProgramSite={updateProgramSite} />
    </div>
  );
};
export default Details;
