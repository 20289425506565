import React from "react"


// Props shared by all sections
export interface SectionSharedProps {
  topOuterDivider?: boolean
  bottomOuterDivider?: boolean
  topDivider?: boolean
  bottomDivider?: boolean
  hasBgColor?: boolean
  invertColor?: boolean
  className?: string
  children?: React.ReactNode
}
export const SectionSharedDefaults: SectionSharedProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,    
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false
};

// Section split props
export interface SectionSplitProps extends SectionSharedProps {
  invertMobile?: boolean
  invertDesktop?: boolean
  alignTop?: boolean
  imageFill?: boolean
}
export const SectionSplitDefaults: SectionSplitProps = {
  ...SectionSharedDefaults,
  invertMobile: false,
  invertDesktop: false,
  alignTop: false,
  imageFill: false,
};

export interface SectionTilesProps extends SectionSharedProps {
  pushLeft?: boolean
}

export const SectionTilesDefaults = {
  ...SectionSharedDefaults,
  pushLeft: false,
};
