import React from 'react';
import DocSearch from '../elements/DocSearch';
import { DocSearchEndpoint } from '../../utils/api';
import { ProgramsAndStuffType } from '../../types';

interface ProgramPickerProps {
  onProgramPicked: (p: ProgramsAndStuffType) => void;
}

const ProgramPicker = ({ onProgramPicked }: ProgramPickerProps) => {
  return (
    <DocSearch
      title="Referral Program"
      subTitle="Programs"
      searchEndpoint={DocSearchEndpoint.getProgramsAndStuff}
      renderDoc={({ site, program, user }: ProgramsAndStuffType) => (
        <div key={program.id || program.siteRef} style={{
          display: 'flex',
          padding: '8px 16px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px solid #ccc',
          color: 'black',
          fontSize: '16px',
          cursor: 'pointer',
        }} onClick={() => onProgramPicked({ program, site, user })}>
          <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>{site.displayName}</div>
          <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>{site.hostname}</div>
          <div>{program.status}</div>
        </div>
      )}
    />
  );

};

export default ProgramPicker;