import React, { ComponentType, useState } from "react";
import ProgramContext from "../../contexts/ProgramContext";
import { makeReactProvider } from "../../utils/makeReactProvider";
import { ProgramsAndStuffType } from "../../types";

const Program = makeReactProvider((Component: ComponentType) => {
  const [loading] = useState(true);
  const [activeProgram, setActiveProgram] = useState<ProgramsAndStuffType | null>(null);
  const [programs, setPrograms] = useState<ProgramsAndStuffType[]>([]);

  return (
    <ProgramContext.Provider
      value={{
        loading,
        programs,
        setPrograms,
        activeProgram,
        setActiveProgram,
      }}
    >
      <Component />
    </ProgramContext.Provider>
  );
});

export default Program;
