import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logos.png";
import AddFaq from "../assets/images/add-faq.png";
// import { LinkStatus, LinkType } from "@tryrelish/relish-types";

interface LayoutDefaultIndexProps {
  children: React.ReactNode;
}

const LayoutDefaultIndex = ({ children }: LayoutDefaultIndexProps) => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div className="main-index">
      <header className="px-5 py-4 mb-3">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </header>
      <div className="tabs mb-4">
        <Container>
          <div className="d-flex justify-content-between">
            <div className="tab-links py-3">
              <Link
                className={
                  splitLocation[1] === "programs" ? "active me-3" : "me-3"
                }
                to="/programs"
              >
                Program
              </Link>
              <Link
                className={splitLocation[1] === "links" ? "active" : ""}
                to="/links"
              >
                Link
              </Link>
            </div>
            <div className="menu-links d-flex">
              <Link
                to="/cms/links/link/add"
                className="d-flex align-items-center me-3"
              >
                <img className="me-2" src={AddFaq} alt="Add Link" /> Add Link
              </Link>
              <Link
                to="/cms/programs/program/add"
                className="d-flex align-items-center me-3"
              >
                <img className="me-2" src={AddFaq} alt="Add Program" /> Add Program
              </Link>
              <Link
                to="/cms/programs/site/add"
                className="d-flex align-items-center me-3"
              >
                <img className="me-2" src={AddFaq} alt="Add Site" /> Add Site
              </Link>
              <Link
                to="/cms/programs/faqs/add"
                className="d-flex align-items-center me-3"
              >
                <img className="me-2" src={AddFaq} alt="Add FAQ" /> Add FAQ
              </Link>
              <Link
                to="/cms/programs/ui-text/add"
                className="d-flex align-items-center"
              >
                <img className="me-2" src={AddFaq} alt="Add UI Text field" />{" "}
                Add UI Text field
              </Link>
            </div>
          </div>
        </Container>
      </div>
      {children}
    </div>
  );
};
export default LayoutDefaultIndex;
