import React from 'react';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

interface FooterProps {
  topOuterDivider?: boolean
  topDivider?: boolean
  className?: string
  amazon?: boolean
}

const Footer = ({
  className,
  topOuterDivider = false,
  topDivider = false,
  amazon = false,
  ...props
}: FooterProps) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className, amazon && 'amazon-site-footer'
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container-xl">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            {amazon ? (
              <h3 style={{ letterSpacing: -2, wordSpacing: 0 }}>
                <span style={{ color: '#7657EE' }}>Relish</span>
                <span style={{ color: '#252525', margin: 0 }}>.club</span>
              </h3>
            ): <Logo />}
            {amazon ? null : <FooterSocial />}
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav amazon={amazon} />
            <div className="footer-copyright text-color-mid">Made with &hearts; in Brooklyn.</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;