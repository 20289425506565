import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HeroImg from './../assets/images/hero.png';
import classNames from 'classnames';

interface LayoutDefaultProps {
  children: React.ReactNode
}

const LayoutDefault = ({ children }: LayoutDefaultProps) => (
  <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
    <img src={HeroImg} alt="Relish Dude" className={classNames('hero-img')} />
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <Footer />
  </div>
);

export default LayoutDefault;  