import React from 'react';
import classNames from 'classnames';

interface FormLabelProps {
  children: React.ReactNode
  labelHidden: boolean
  className?: string
  id: string
}

const FormLabel = ({
  className,
  children,
  labelHidden,
  id,
  ...props
}: FormLabelProps) => {

  const classes = classNames(
    'form-label',
    labelHidden && 'screen-reader',
    className
  );

  return (
    <label
      {...props}
      className={classes}
      htmlFor={id}
    >
      {children}
    </label>
  );
};

export default FormLabel;