import React from 'react';
import blogData from '../assets/json/blog.json';

const BlogJSON = () => {
  const { items } = blogData;
  return (
    <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
      {items.map(item => (
        <div key={item.link}>
          <div>
            {item.title}
          </div>
          <div>{item.description}</div>
          <div>{item.categories.toString()}</div>
          <div>{item.content}</div>
          <a href={item.link}>{item.link}</a>
        </div>
      ))}
    </div>
  )
};

export default BlogJSON;