import React from 'react';
import Header from '../components/amazon/layout/Header';
import Footer from '../components/layout/Footer';

interface LayoutDefaultProps {
  children: React.ReactNode
}

const LayoutAmazon = ({ children }: LayoutDefaultProps) => (
  <div style={{ maxWidth: '100%', overflowX: 'hidden', backgroundColor: '#E3E6E6' }}>
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <Footer amazon />
  </div>
);

export default LayoutAmazon;  