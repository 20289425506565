import React from 'react';
import classNames from 'classnames';
import { SectionTilesDefaults, SectionTilesProps } from '../../utils/SectionProps';
import ShopImg from './../../assets/images/shop.png';
import ChromeImg from './../../assets/images/chrome.png';
import EarnImg from './../../assets/images/earn.png';

const Overview = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertColor,
  pushLeft,
  ...props
}: SectionTilesProps = SectionTilesDefaults) => {

  const outerClasses = classNames(
    'overview section bg-color-secondary',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'overview-inner section-inner pt-0 overview-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{
        padding: '1em 0',
        backgroundColor: '#ED7954',
        color: '#FDFDFD'
      }}
    >
      <div className="container-xl overview-section">
        <div className={innerClasses}>
          <div className="ta-c overview-container">
            <div className="overview-column">
              <h4 className="overview-column-title">Install</h4>
              <div className="overview-column-img">
                <img width={60} src={ChromeImg} alt="Install our extension" />
              </div>
              {/* <div style={{ padding: '8px 0 24px', fontSize: 18 }}>It's free 'n easy and lives in your browser</div> */}
            </div>
            <div className="overview-column">
              <h4 className="overview-column-title">Shop</h4>
              <div className="overview-column-img">
                <img style={{ position: 'relative', left: '-5px' }} width={60} src={ShopImg} alt="Shop like normal" />
              </div>
              {/* <div style={{ padding: '8px 0 24px', fontSize: 18 }}>We'll find the deals automatically.</div> */}
            </div>
            <div className="overview-column">
              <h4 className="overview-column-title">Save</h4>
              <div className="overview-column-img" >
                <img width={80} src={EarnImg} alt="Save!" />
              </div>
              {/* <div style={{ padding: '8px 0 24px', fontSize: 18 }}>Share and find referral codes, anywhere!</div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Overview;