import { FaqType, LinkType, Path, ProgramType, SiteType, UiTextType } from "@tryrelish/relish-types";
import { Functions, connectFunctionsEmulator } from "firebase/functions";
import { BlogItemType, LinksAndStuffType, ProgramsAndStuffType, UserStatsType, OwnersType } from "../types";
import { functionWrapper } from "./functionWrapper";
import { IS_DEV } from "./index";

export enum DocSearchEndpoint {
  getSites = 'getSites',
  getProgramsAndStuff = 'getProgramsAndStuff',
}

class Api {
  functions;

  constructor(functions: Functions) {
    this.functions = functions;
    if (IS_DEV) {
      connectFunctionsEmulator(this.functions, "localhost", 5001);
    }
  }

  subscribeUser = async (email: string) => {
    const { data } = await functionWrapper<any, { details: { code: string } }>(
      this.functions,
      "addMCSubscriber",
      {
        email,
      }
    );
    if (data.details.code === 'ok') {
      return { success: true, error: null };
    } else {
      console.log("Failed", data);
      return { success: false, error: data };
    }
  };

  saveProgram = async (program?: ProgramType): Promise<{ success: boolean, program?: ProgramType, error?: string }> => {
    const { data } = await functionWrapper<any, { success: boolean, program: ProgramType, error?: string }>(
      this.functions,
      "saveProgram",
      { program },
    );
    if (data.success) {
      return { success: true, program: data.program };
    } else {
      console.log("Failed", data);
      return { success: false, error: data.error };
    }
  };

  saveLink = async (link: Partial<LinkType>) => {
    const { data } = await functionWrapper<any, { success: boolean }>(
      this.functions,
      "saveLink",
      link
    );
    if (data.success) {
      return { success: true, error: null };
    } else {
      console.log("Failed", data);
      return { success: false, error: data };
    }
  };

  addFaq = async (faq: FaqType) => {
    const { data } = await functionWrapper<any, { success: boolean }>(
      this.functions,
      "addFaq",
      faq
    );
    if (data.success) {
      return { success: true, error: null };
    } else {
      console.log("Failed", data);
      return { success: false, error: data };
    }
  };

  getFaqs = async () => {
    const { data } = await functionWrapper<any, { faqs: FaqType[] }>(
      this.functions,
      "getFaqs",
      {}
    );
    return data;
  };

  addUiText = async (uitext: UiTextType) => {
    const { data } = await functionWrapper<UiTextType, { success: boolean }>(
      this.functions,
      "addUiText",
      uitext
    );
    if (data.success) {
      return { success: true, error: null };
    } else {
      console.log("Failed", data);
      return { success: false, error: data };
    }
  };

  getUiText = async () => {
    const { data } = await functionWrapper<any, { uitext: UiTextType[] }>(
      this.functions,
      "getUiText",
      {},
    );
    return data;
  };

  getBlog = async () => {
    const { data } = await functionWrapper<any, { items: BlogItemType[] }>(
      this.functions,
      "getBlog",
      {}
    );
    return data.items;
  };

  getOwners = async () => {
    const { data } = await functionWrapper<
      any,
      OwnersType[]
    >(this.functions, "getOwners", {});
    return data;
  }

  getProgramsAndStuff = async (limit = 10, offset = 0, company = '', owner = '', status = '') => {
    const { data } = await functionWrapper<
      any,
      ProgramsAndStuffType[]
    >(this.functions, "getProgramsAndStuff", { limit, offset, status, owner, company });
    return data;
  };

  getProgramAndStuffById = async (id: string) => {
    const { data } = await functionWrapper<
      { id: string },
      ProgramsAndStuffType
    >(this.functions, "getProgramAndStuffById", { id });
    return data;
  };

  getLinksByProgramId = async (id: string) => {
    const { data } = await functionWrapper<any, LinkType[]>(
      this.functions,
      "getLinksByProgramId",
      { id }
    );
    return data;
  };

  getStats = async () => {
    const { data } = await functionWrapper<any, { userStats: UserStatsType[] }>(
      this.functions,
      "getAdminStats",
      {},
    );
    const { userStats } = data;
    return userStats;
  }

  getLinksAndStuff = async (limit = 10, offset = 0, status = '', site = '') => {
    const { data } = await functionWrapper<
      any,
      LinksAndStuffType[]
    >(this.functions, "getLinksAndStuff", { limit, offset, status, site });
    return data;
  };

  getSite = async (siteRef: Path) => {
    const { data } = await functionWrapper<
      string,
      SiteType
    >(this.functions, 'getSite', siteRef);
    return data;
  }

  getSites = async (limit = 10, offset = 0, name = '') => {
    const { data } = await functionWrapper<
      any,
      SiteType[]
    >(this.functions, 'getSites', { limit, offset, name });
    return data;
  }

  saveSite = async (s: { displayName: string, hostname: string, id?: string }) => {
    const { displayName, hostname, id } = s;
    const { data } = await functionWrapper<
      any,
      { updatedSite: SiteType }
    >(this.functions, 'saveSite', { displayName, hostname, id });
    return data;
  };

  addAdminLink = async (link: Partial<LinkType>) => {
    const { data } = await functionWrapper<
      any,
      LinkType[]
    >(this.functions, 'addAdminLink', { link });
    return data;
  };
}

export default Api;
