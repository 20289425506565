import React from 'react';
import firebase from 'firebase/compat/app';

type UIConfigType = {
  signInFlow: string,
  signInOptions: string[],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any) => boolean,
  }
};

// Configure FirebaseUI.
export const uiConfig = (callback: (authResult: any) => void): UIConfigType => ({
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: authResult => {
      callback(authResult);
      return false
    },
  },
});

export const uiConfigRedirect = (callback: (authResult: any) => void): UIConfigType => ({
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: authResult => {
      callback(authResult);
      return false
    },
  },
});


type FirebaseContextModel = {
  signOut: () => void;
  auth: typeof firebase.auth | null;
  uiConfig: (callback: (authResult: any) => void) => UIConfigType;
  uiConfigRedirect: (callback: (authResult: any) => void) => UIConfigType;
};

const FirebaseContext = React.createContext<FirebaseContextModel>({
  signOut: () => {},
  auth: null,
  uiConfig,
  uiConfigRedirect,
});

export default FirebaseContext;
