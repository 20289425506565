import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Table, Form } from "react-bootstrap";
import Edit from "../../../assets/images/edit.png";
import Filter from "../../../assets/images/filter.png";
import Arrow from "../../../assets/images/arrow.png";
import FilterDark from "../../../assets/images/filter-dark.png";
import AuthContext from "../../../contexts/AuthContext";
import Loading from "react-loading";
import { LinkStatus, LinkType, Path, SiteType, UserType } from "@tryrelish/relish-types";
import { LinksAndStuffType } from "../../../types";
import { PAGE_SIZE } from "../../../constants";
import { withPagination } from "../../providers/Pagination";
import PaginationContext from "../../../contexts/PaginationContext";
import UserImage from "../../elements/UserImage";
import SiteChangeModal from "../../Program/SiteChangeModal";

interface LinkRowProps {
  link: LinkType,
  user?: UserType,
  site?: SiteType,
  owner?: UserType,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  updateStatus: (linkId: string, status: LinkStatus, userPath: Path) => void,
  updateLink: (linkId: string, link: string, userPath: Path, siteRef?: Path) => void,
  loggedInUserPath: Path,
}

const LinkRow = ({
  link,
  user,
  site,
  owner,
  updateStatus,
  updateLink,
  isEditing,
  setIsEditing,
  loggedInUserPath,
}: LinkRowProps) => {
  const [siteModalOpen, setSiteModalOpen] = useState(false);
  const [editLink, setEditLink] = useState<Partial<LinkType>>({});

  const updateProgramSite = (s: SiteType) => {
    console.log('Setting site', s.displayName);
    updateLink(link.id!, link.referralLink, loggedInUserPath, s.path);
  };

  return (
    <tr key={link.id}>
      <td><UserImage user={owner || null} /></td>
      <td className="text-center">
        <UserImage user={user || null} />
      </td>
      <td onClick={() => setSiteModalOpen(true)} style={{ minWidth: 50 }}>{site?.displayName}</td>
      <td>
        <div className="link-text">
          {editLink?.id === link.id && isEditing === true ? (
            <Form.Control
              type="email"
              placeholder="https://adwallet.com/?..."
              value={editLink?.referralLink}
              onChange={e =>
                setEditLink({
                  ...editLink,
                  referralLink: e.target.value,
                })
              }
              onBlur={e =>
                updateLink(editLink.id!, e.target.value, loggedInUserPath, site?.path)
              }
              autoFocus
            />
          ) : (
            <>
              {" "}
              <a href={link.referralLink} target="_blank" rel="noopener noreferrer">
                {link.referralLink}
              </a>{" "}
              <span
                className="ms-2"
                onClick={() => {
                  setEditLink(link);
                  setIsEditing(true);
                }}
              >
                <img src={Edit} alt="Edit" style={{ width: 25 }} />
              </span>
            </>
          )}
        </div>
      </td>
      <td>
        <div className="dropdown-select">
          <span>
            {link.status}
            <img className="arrow ms-2" src={Arrow} alt="arrow" />
          </span>
          <div className="open-dropdown">
            <ul className="p-0 m-0">
              {Reflect.ownKeys(LinkStatus).map(k => (
                <li
                  key={`${link.id!}-${k as string}-update`}
                  onClick={() =>
                    updateStatus(link.id!, k as LinkStatus, loggedInUserPath)
                  }
                  className={link.status === k ? "active" : ""}
                >
                  {k as string}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <SiteChangeModal show={siteModalOpen} handleClose={() => setSiteModalOpen(false)} updateProgramSite={updateProgramSite} />
      </td>
    </tr>
  );
};

const LinksList = ({ currCompany }: { currCompany?: string }) => {
  const [linksAndStuff, setLinksAndStuff] = useState<LinksAndStuffType[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [company, setCompany] = useState(currCompany);
  const [isCompanySearch, setIsCompanySearch] = useState(false);

  const { setHasNext, page, setPage } = useContext(PaginationContext);

  const companyInput = useRef<HTMLInputElement>(null);

  const { api, user: loggedInUser } = useContext(AuthContext);

  const getLinksAndStuff = useCallback(async () => {
    setLoading(true);
    if (api) {
      const data = await api.getLinksAndStuff(
        PAGE_SIZE,
        (page - 1) * PAGE_SIZE,
        filter,
        company,
      );
      setLinksAndStuff(data);
      if (data.length < PAGE_SIZE) {
        setHasNext(false);
      }
    }
    setLoading(false);
  }, [api, page, filter, company]);

  const updateStatus = (linkId: string, status: LinkStatus, userPath: Path) => {
    api
      ?.saveLink({ id: linkId, status, lastModifiedBy: userPath })
      .then(() => {
        setIsEditing(false);
        const editedLinkIndex = linksAndStuff.findIndex(l => l.link.id === linkId);
        const nextLink: LinksAndStuffType = {
          link: {
            ...linksAndStuff[editedLinkIndex].link,
            status,
          },
          site: linksAndStuff[editedLinkIndex].site ? {
            ...linksAndStuff[editedLinkIndex].site!,
          }: undefined,
        };
        if (linksAndStuff[editedLinkIndex].user) {
          nextLink.user = linksAndStuff[editedLinkIndex].user;
        }

        setLinksAndStuff([
          ...linksAndStuff.slice(0, editedLinkIndex),
          nextLink,
          ...linksAndStuff.slice(editedLinkIndex + 1),
        ]);
      })
      .catch(error => console.log(error));
  };

  const updateLink = (linkId: string, link: string, userPath: Path, siteRef?: Path) => {
    api
      ?.saveLink({ id: linkId, referralLink: link, lastModifiedBy: userPath, siteRef })
      .then(async () => {
        setIsEditing(false);
        await getLinksAndStuff();
      })
      .catch(error => console.log(error));
  };

  const filterLinks = (status: LinkStatus) => {
    setFilter(status);
  };

  useEffect(() => {
    getLinksAndStuff()
      .then(() => {})
      .catch(() => {});
  }, [page, filter, company]);

  if (!api || loading || !loggedInUser) {
    return (
      <div
        style={{
          minHeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading color="#252525" />
      </div>
    );
  }

  const formElement = (
    <form onSubmit={e => {
      e.preventDefault();
      if (companyInput.current) {
        setCompany(companyInput.current.value);
        setPage(1);
        companyInput.current.blur();
      }
    }}>
      <input ref={companyInput} type="text" onBlur={e => {
        setIsCompanySearch(false);
      }} />
    </form>
  );

  const getCompanySearch = () => {
    if (isCompanySearch) {
      setTimeout(() => {
        if (companyInput.current) {
          companyInput.current.focus();
        }
      }, 5);
      return formElement;
    } else {
      if (company) {
        return (
          <div
            onClick={() => {
              setCompany('');
              setPage(1);
            }}
            style={{ textDecoration: 'underline' }}
          >
            {company}
          </div>);
      } else {
        return <span onClick={() => setIsCompanySearch(true)}>Company</span>
      }
    }
  }

  return (
    <Table className="bg-transparent mb-0">
      <thead>
        <tr className="position-relative">
          <th className="text-center">Owner</th>
          <th className="text-center">User</th>
          <th style={{ textDecoration: "underline" }}>
            {getCompanySearch()}
          </th>
          <th>Link</th>
          <th>
            <div className="dropdown-select">
              <span>
                Status{" "}
                <img className="arrow ms-2" src={Filter} alt="Filter" />
              </span>
              <div className="open-dropdown">
                <h6 className="mb-0 mt-2 pt-1">
                  <img
                    className="arrow me-1"
                    src={FilterDark}
                    alt="Filter"
                  />{" "}
                  Status filter
                </h6>
                <ul className="p-0 m-0">
                  {Reflect.ownKeys(LinkStatus).map(k => (
                    <li
                      key={k as string}
                      onClick={() => filterLinks(k as LinkStatus)}
                      className={filter === k ? "active" : ""}
                    >
                      {k as string}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {linksAndStuff.map(({ link, user, site, owner }: LinksAndStuffType) => (
          <LinkRow
            key={link.id}
            link={link}
            user={user}
            site={site}
            owner={owner as UserType}
            updateStatus={updateStatus}
            updateLink={updateLink}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            loggedInUserPath={loggedInUser.path!}
          />
        ))}
      </tbody>
    </Table>
  );
};
export default withPagination(LinksList);
