import React, { ComponentType, useEffect, useState } from "react";
import PaginationContext, { PaginationContextProps } from "../../contexts/PaginationContext";
import { Container } from "react-bootstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

const PaginationHelper = (props: PaginationContextProps) => {
  const {
    page,
    setPage,
    hasNext,
  } = props;

  const onNext = () => {
    setPage(page + 1);
  };

  const onPrevious = () => {
    setPage(page - 1);
  };

  return (
    <div className="pagination bg-white pt-3 pb-5">
      <ul className="m-0 p-0 d-flex justify-content-center w-100">
        {/* Left navigation arrow */}
        {page !== 1 && (
          <li onClick={onPrevious}>
            <span className="d-flex justify-content-center align-items-center">
              Prev
            </span>
          </li>
        )}
        <li
          key={page}
          className="active"
          style={{ margin: '0 16px' }}
        >
          <span className="d-flex justify-content-center align-items-center">
            {page}
          </span>
        </li>
        {/*  Right Navigation arrow */}
        {hasNext && (
          <li onClick={onNext}>
            <span className="d-flex justify-content-center align-items-center">
              Next
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

const Pagination = <T,>(Component: ComponentType<T>) => {
  const ComponentWithPagination = (props: T) => {
    const nav = useNavigate();
    const loc = useLocation();
    const [params] = useSearchParams();

    const [page, setPage] = useState(1);
    const [hasNext, setHasNext] = useState(true);

    useEffect(() => {
      const paramsPage = params.get('page') ? Number(params.get('page')) : null;
      if (paramsPage) {
        setPage(paramsPage);
      }
    }, [params])
    
    const setPageHelper = (n: number) => {
      const { pathname } = loc;
      setPage(n);
      nav({
        pathname,
        search: new URLSearchParams({ ...params, page: String(n) }).toString(),
      })
    };
    return (
      <PaginationContext.Provider
        value={{
          page,
          hasNext,
          setHasNext,
          setPage: setPageHelper,
        }}
      >
        <Container>
          <Component {...props} />
          <PaginationHelper
            page={page}
            setPage={setPageHelper}
            setHasNext={setHasNext}
            hasNext={hasNext}
          />
        </Container>
      </PaginationContext.Provider>
    )
  };

  return ComponentWithPagination
};

export const withPagination = Pagination;

export default Pagination;
