import React from 'react';

const Privacy = () => {
  return (
    <section className="section mission-section">
      <div className="container mission-container" style={{ minHeight: 600 }}>
        <div className="section-inner">
          <div className="mission-title">
            <h1
              className="ta-c mt-0 mb-32 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Privacy Policy
            </h1>
          </div>
          <div>
            <p>
              At Relish, we store and use some of your personal data to help make your experience better.
              We only ever store this data in our database, and will never share it with other 3rd-parties that would use it to spam you with ads.
            </p>
            <p>
              We save your email so you can opt-in to notifications, and we store your name so that we can communicate with you better. That&apos;s it!
            </p>
            <p>
              &hearts; the UseRelish.com team
            </p>
          </div>
      </div>
    </div>
  </section>
  );
}

export default Privacy;