import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface FooterNavProps {
  className?: string
  amazon?: boolean
  [prop: string]: any
}

const FooterNav = ({
  className,
  amazon = false,
  ...props
}: FooterNavProps) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="mailto:dave@userelish.com">Contact</a>
        </li>
        <li>
          <Link to="/about">About us</Link>
        </li>
        <li>
          <Link to="/about">FAQs</Link>
        </li>
        <li style={{ color: '#b1b1b1' }}>
          &copy; 2023 {amazon ? 'Club Relish' : 'Sepiidae Holdings, LLC'}
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;