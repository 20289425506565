import React from 'react';
import classNames from 'classnames';

interface ButtonProps {
  className?: string
  tag?: 'button' | 'a'
  color?: string
  size?: string
  loading?: boolean
  wide?: boolean
  wideMobile?: boolean
  disabled?: boolean
  children?: React.ReactNode
  [prop: string]: any
}

const Button = ({
  className,
  tag = 'button',
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  ...props
}: ButtonProps) => {

  const classes = classNames(
    'button',
    color && `button-${color}`,
    size && `button-${size}`,
    loading && 'is-loading',
    wide && 'button-block',
    wideMobile && 'button-wide-mobile',
    className
  );

  const Component = tag;
  return (
    <Component
      {...props}
      className={classes}
      disabled={disabled}
    />
  );
};

export default Button;