import React from 'react';
import DavidImg from '../../assets/images/me.jpeg';
import JinsilImg from '../../assets/images/jinsil.jpeg';
import HimalImg from '../../assets/images/himal.jpeg';

const Team = () => {
  const team = [
    {
      name: 'Dave Witwer',
      title: 'CEO',
      image: DavidImg,
    },
    {
      name: 'Jinsil Nha',
      title: 'Design Director',
      image: JinsilImg
    },
    {
      name: 'Himal Sherchan',
      title: 'Engineering Lead',
      image: HimalImg,
    },
  ];

  return (
    <section className="section team-section">
      <div className="container team-container">
        <div className="section-inner">
          <h1 className="ta-c mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
            Our Team
          </h1>
          <div className="team-cards">
            {team.map(t => (
              <div className="ta-c team-card" key={t.name}>
                <img className="team-image" src={t.image} alt={t.name} />
                <div className="team-name">{t.name}</div>
                <div className="team-title">{t.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
};

export default Team;