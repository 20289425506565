import { ProgramType, SiteType, UserType } from "@tryrelish/relish-types";
import React, { useContext, useEffect, useState } from "react";
import Loading from "react-loading";
import { useParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import ProgramContext from "../../contexts/ProgramContext";
import { ProgramsAndStuffType } from "../../types";
import CreateOrUpdateProgram from "./CreateOrUpdateProgram";

const EditProgramHelper = ({ program, site, user }: { program: ProgramType, site: SiteType, user: UserType | null }) => (
  <CreateOrUpdateProgram existingProgram={program} existingSite={site} existingUser={user} />
);

const EditProgram = () => {
  const { activeProgram, setActiveProgram } = useContext(ProgramContext)
  const { api } = useContext(AuthContext);
  const [program, setProgram] = useState(activeProgram ? { ...activeProgram.program } : null);
  const [site, setSite] = useState(activeProgram ? { ...activeProgram.site  } : null);
  const [user, setUser] = useState(activeProgram ? { ...activeProgram.user  } as UserType : null);
  const { id } = useParams();

  useEffect(() => {
    if (!api) {
      return;
    }

    if (!program || !site) {
      if (id) {
        api.getProgramAndStuffById(id)
          .then(((programAndStuff: ProgramsAndStuffType) => {
            setActiveProgram(programAndStuff);
            setProgram(programAndStuff.program);
            setSite(programAndStuff.site);
            setUser(programAndStuff.user);
          })).catch(e => {
            console.log(e);
          });
      }
    }
  }, [id]);

  if (!site || !program) {
    return (
      <div
        style={{
          minHeight: 600,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loading color="#252525" />
      </div>
    );
  }
  return <EditProgramHelper site={site} program={program} user={user} />
};
export default EditProgram;
