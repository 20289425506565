import React from 'react';
import { UserType } from '@tryrelish/relish-types';
// import Image from "react-bootstrap/Image";

const getHoverComponent = (user: UserType | null) => {
  if (!user) {
    return null;
  }

  return (
    <span className="bg-71D8A4 d-inline-flex  justify-content-center  align-items-center">
      {user.displayName.split(' ').map(a => a[0]).slice(0, 2)}
    </span>
  );

  // return (
  //   <Image
  //     roundedCircle
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //     src={user?.profileImg}
  //     width={45}
  //     height={45}
  //   />
  // );
};

const UserImage = ({ user }: { user: UserType | null }) => (
  <div className="user-img">
    {getHoverComponent(user)}

    <div className="hover-box-open">
      <div className="d-flex">
        <div className="imges">
          {getHoverComponent(user)}
        </div>
        <div className="ms-3">
          {user ? (
            <>
              <h6 className="mb-0">{user?.displayName}</h6>
              <p className="mb-0">{user?.email}</p>
            </>
          ) : (
            <>
              <h6 className="mb-0">Relish</h6>
              <p className="mb-0">admin@relish.com</p>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default UserImage;