import React, { useEffect } from 'react';
import classNames from 'classnames';

interface ModalProps {
  children?: React.ReactNode
  handleClose: (e: React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent) => void
  show?: boolean
  closeHidden?: boolean
  video?: string
  videoTag?: 'iframe' | 'video'
  className?: string
  id: string
}

const Modal = ({
  className,
  children,
  handleClose,
  show = false,
  closeHidden = false,
  video = '',
  videoTag = 'iframe',
  ...props
}: ModalProps) => {

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };    
  });

  useEffect(() => {
    handleBodyClass();
  }, [show]); 
  
  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  }

  const keyPress = (e: React.KeyboardEvent | KeyboardEvent) => {
    (e.keyCode || e.key) === 27 && handleClose(e);
  }

  const stopProgagation = (e: React.MouseEvent | MouseEvent) => {
    e.stopPropagation();
  }

  const classes = classNames(
    'modal',
    show && 'is-active',
    video && 'modal-video',
    className
  );

  return (
    <>
      {show &&
        <div
          {...props}
          className={classes}
          onClick={handleClose}
        >
          <div className="modal-inner" onClick={stopProgagation}>
            {video ?
              <div className="responsive-video">
                {videoTag === 'iframe' ?
                  <iframe
                    title="video"
                    src={video}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe> :
                  <video
                    v-else
                    controls
                    src={video}
                  ></video>
                }
              </div> :
              <>
                {!closeHidden &&
                  <button
                    className="modal-close"
                    aria-label="close"
                    onClick={e => handleClose(e)}
                  ></button>
                }
                <div className="modal-content">
                  {children}
                </div>
              </>
            }
          </div>
        </div>
      }
    </>
  )
};

export default Modal;